import {
  Box,
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import * as tableHead from "../../constants/table.js";

export default function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, selectedRatios } = props;

  return (
    <TableHead sx={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }}>
      <TableRow>
        <TableCell
          key="name"
          align="left"
          sx={{ fontWeight: "bold", paddingLeft: "30px" }}
        >
          Empresa
        </TableCell>
        {tableHead.headCells.map(
          (headCell) =>
            selectedRatios[headCell.id] && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "center" : "left"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ fontWeight: "bold", paddingLeft: "30px" }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => onRequestSort(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}
