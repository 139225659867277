import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Copiado from "./portfolio/Copiado";

const TablePorfolio = ({ columns, rows, eliminarAccion }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ borderRadius: 3 }}>
      <TableContainer sx={{ borderRadius: 3 }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    fontWeight: "bold",
                    width: column.width,
                  }}
                  //autoWidth
                  align={
                    column.id === "ticker" || column.id === "name"
                      ? "left"
                      : "center"
                  }
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                key="eliminar"
                align="center"
                sx={{
                  fontWeight: "bold",
                }}
              >
                <Copiado acciones={rows}></Copiado>
              </TableCell>
              {/* Nueva columna para el botón de eliminar */}
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Ajusta maxHeight según tus necesidades */}
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    sx={{ "& > *": { py: "3px" } }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={
                            column.id === "ticker" || column.id === "name"
                              ? "left"
                              : "center"
                          }
                          sx={{
                            fontWeight:
                              column.id === "ticker" ? "bold" : "normal",
                            padding: {
                              xs:
                                column.id === "valorUSD" ||
                                column.id === "variacion"
                                  ? 0
                                  : undefined,
                            },
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell align="center">
                      <IconButton onClick={() => eliminarAccion(row.ticker)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default TablePorfolio;
