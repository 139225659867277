import { Box, Button, Typography } from "@mui/material";
import React from "react";
import * as button from "../../styles/button";

const FrontCard = ({
  onMoveToTopClick,
  isAtTop,
  setFront,
  image,
  symbol,
  description,
  ticker,
  stock,
}) => {
  const cardStyle = {
    width: 300,
    maxHeight: 470,
    boxShadow: 6,
    borderRadius: 5,
    marginLeft: "10px",
    marginRight: { xs: "10px", md: "30px" },
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "white",
  };
  const boxImage = {
    height: 100,
    padding: 1,
    display: "flex",
  };

  return (
    <Box sx={cardStyle}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={boxImage}>
          <img
            src={image}
            alt={stock.name}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Box>
      <Box>
        <Button
          sx={{
            ...button.styleButton,
            margin: "20px",
            width: "15rem",
            height: "35px",
          }}
          onClick={() => {
            setFront(false);
          }}
        >
          <Typography
            sx={{ ...button.styleButtonText, textTransform: "uppercase" }}
          >
            Informacion Financiera
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default FrontCard;
