import React from "react";
import { Box, Typography } from "@mui/material";
import * as title from "../../constants/titles_home.js";
import * as styles from "../../styles/Home.js";
import imageSVG from "../../utils/images/Balance.svg";

const Acciones = () => {
  return (
    <Box sx={styles.styleContenedorAcciones}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={styles.styleTextcardTitle}>
          ¿Por qué Acciones?
        </Typography>
      </Box>
      <Box sx={styles.styleContenedorAccionesImage}>
        <Box sx={styles.styleAccionsImage}>
          <img
            style={{ width: "100%" }}
            src={imageSVG}
            alt="Descripción de la imagen"
          />
        </Box>
        <Box sx={styles.styleAccionesTextBox}>
          {/* Mapea los elementos de title.porqueAcciones y devuelve un elemento Box para cada uno */}
          {title.porqueAcciones.map((data, index) => (
            <Typography key={index} sx={styles.styleAccionesText}>
              {data.text}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Acciones;
