import { Box, Toolbar, Tooltip, Typography } from "@mui/material";
import * as styles from "../../styles/MoreFinancial";

const BoxCard = ({ text, value, color, sx, tooltip }) => {
  return (
    <Box sx={styles.styleBoxContainer}>
      <Tooltip title={tooltip}>
      <Typography sx={styles.styleBoxText}>{text}</Typography>
      </Tooltip>
      <Box sx={styles.styleBox(color)}>
        <Tooltip title={tooltip}>
        <Typography
          sx={{
            ...styles.styleBoxValue,
            fontWeight: "bold",
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        ></Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};
export default BoxCard;
