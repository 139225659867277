import React from "react";
import { Box } from "@mui/material";
import Tag from "../Tag";
import Table from "../Table";

const TablePorfolio = ({
  styles,
  acciones,
  functions,
  stocks,
  dolarCCL,
  handleEliminarAccion,
}) => {
  return (
    <Box sx={styles.containerTable}>
      <Table
        columns={[
          { id: "ticker", label: "Código" },
          { id: "name", label: "Nombre" },
          { id: "cantidad", label: "Cantidad" },
          { id: "price", label: "Precio USD" },
          { id: "valorUSD", label: "Valor" },
          { id: "variacion", label: "Variación" },
          { id: "porcentaje", label: "% del Portfolio" },
        ]}
        rows={acciones.map((accion, index) => {
          const variacion = functions
            .calcularVariacion(accion, stocks)
            .toLocaleString("es-ES", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          return {
            ticker: accion.ticker,
            name: stocks.find((stock) => stock.ticker === accion.ticker)?.name,
            cantidad: accion.cantidad,
            price: `$ ${functions.calcularPrecioCedear(accion, stocks)}`,
            valorUSD: `$ ${functions.valorAccionUSD(
              accion.ticker,
              accion.cantidad,
              stocks,
              dolarCCL
            )}`,
            variacion: (
              <Tag
                text={` ${
                  variacion > 0 ? "🠝" : variacion < 0 ? "🠟" : ""
                } ${Math.abs(variacion).toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} %`}
                colorChoice={
                  variacion > 0 ? "green" : variacion < 0 ? "red" : "default"
                }
              />
            ),
            porcentaje: `${functions.calcularPorcentaje(
              accion.cantidad,
              accion.ticker,
              functions.calcularValorTotal("ARS", acciones, stocks, dolarCCL),
              stocks,
              dolarCCL
            )} %`,
          };
        })}
        eliminarAccion={handleEliminarAccion}
      />
    </Box>
  );
};
export default TablePorfolio;
