import { Route, Routes } from "react-router";
import ResponsiveAppBar from "./components/Nav";
import Home from "./pages/Home";
import Cedears from "./pages/Cedears";
import Screener from "./pages/Screener";
import Ratios from "./components/Ratios";
import Footer from "./components/Footer";
import MiPortfolio from "./pages/MiPortfolio";
import Layout from "./components/Layout";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: { xs: 300, sm: 600, md: 960, lg: 1280, xl: 1920, xxl: 2000 },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Cedears" element={<Cedears />} />
          <Route path="Screener" element={<Screener />} />
          <Route path="Ratios" element={<Ratios />} />
          <Route path="MiPortfolio" element={<MiPortfolio />} />
        </Routes>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
