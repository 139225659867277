import { minHeight } from "@mui/system";

export const styleContainer = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "start",
  paddingBottom: "40px",
  width: "100%",
};
export const myStyle2 = {
  paddingTop: { xs: 1, md: 5 },
  display: "flex",
  flexDirection: { xs: "row" },
  maxWidth: { xs: "100%" },
  whiteSpace: { xs: "nowrap" },
  marginBottom: -2,
  paddingInline: "3rem",
};
export const styleButton = {
  marginLeft: 1,
  marginRight: 1,
  border: 1,
  borderColor: "white",
  color: "white",
  marginBottom: 1.5,
  maxHeight: { xs: "35px" },
};
export const styleTextField = {
  marginRight: "50px",
  backgroundColor: "white",
  borderRadius: 2,
  height: "40px",

  "& fieldset": {
    border: "none",
  },

  "& .MuiInputLabel-root.Mui-focused": {
    color: "#2A4887",
    marginTop: "1px",
  },
};
export const containerPrincipal = {
  display: "flex",
  flexDirection: { xs: "column" },
  width: "100%",
  minHeight: "100vh",
};
export const styleTextFiltros = {
  fontSize: 25,
  marginLeft: 2,
  color: "white",
  marginBottom: 1.5,
  display: { xs: "none" },
  textAlign: "center",
};
