import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Line,
} from "recharts";

// Función para formatear los números en miles
const formatThousands = (value) => `${(value / 1000).toFixed(2)}B`;
const formatThousandsNoDecimals = (value) => `${(value / 1000).toFixed(0)}B`;
const formatPercentages = (value) => `${value.toFixed(2)}%`;

const VentasChart = ({ stock }) => {
  const [chartWidth, setChartWidth] = useState(); // Inicializar el ancho del gráfico al 20% del ancho de la ventana
  const [chartHeight, setChartHeight] = useState(); // Inicializar el alto del gráfico al 20% del alto de la ventana

  const theme = useTheme();

  const calculateNewWidth = () => {
    const { xs, sm, md, lg, xl } = theme.breakpoints.values;

    if (window.innerWidth <= sm) {
      return [window.innerWidth * 0.85, window.innerHeight * 0.32];
    } else if (window.innerWidth <= md) {
      return [window.innerWidth * 0.72, window.innerHeight * 0.32]; // ajusta el valor para sm
    } else if (window.innerWidth < lg) {
      return [window.innerWidth * 0.7, window.innerHeight * 0.32]; // ajusta el valor para md// ajusta el valor para lg
    } else if (window.innerWidth <= xl) {
      return [window.innerWidth * 0.28, window.innerHeight * 0.4]; // ajusta el valor para lg
    } else {
      return [window.innerWidth * 0.35, window.innerHeight * 0.4]; // ajusta el valor para xl
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Verificar el ancho de la ventana

      let [newWidth, newHeight] = calculateNewWidth();

      // Actualizar el tamaño del gráfico
      setChartWidth(newWidth);
      setChartHeight(newHeight);
    };

    // Agregar el listener para el evento resize
    window.addEventListener("resize", handleResize);

    // Llamar a la función de manejo de resize para establecer las dimensiones iniciales
    handleResize();

    // Limpiar el listener del evento resize al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = [
    {
      name: "2018",
      MargenNeto: (stock.NetIncome2018 / stock.Revenue2018) * 100,
      Ventas: stock.Revenue2018,
      Beneficios: stock.NetIncome2018,
    },
    {
      name: "2019",
      MargenNeto: (stock.NetIncome2019 / stock.Revenue2019) * 100,
      Ventas: stock.Revenue2019,
      Beneficios: stock.NetIncome2019,
    },
    {
      name: "2020",
      MargenNeto: (stock.NetIncome2020 / stock.Revenue2020) * 100,
      Ventas: stock.Revenue2020,
      Beneficios: stock.NetIncome2020,
    },
    {
      name: "2021",
      MargenNeto: (stock.NetIncome2021 / stock.Revenue2021) * 100,
      Ventas: stock.Revenue2021,
      Beneficios: stock.NetIncome2021,
    },
    {
      name: "2022",
      MargenNeto: (stock.NetIncome2022 / stock.Revenue2022) * 100,
      Ventas: stock.Revenue2022,
      Beneficios: stock.NetIncome2022,
    },
    {
      name: "2023",
      MargenNeto: (stock.NetIncome2023 / stock.Revenue2023) * 100,
      Ventas: stock.Revenue2023,
      Beneficios: stock.NetIncome2023,
    },
  ];

  const margenNetoValues = data.map((entry) => entry.MargenNeto);
  const margenNetoMin = Math.min(...margenNetoValues);
  const margenNetoMax = Math.max(...margenNetoValues);

  const ventasValues = data.map((entry) => entry.Ventas);
  const beneficiosValues = data.map((entry) => entry.Beneficios);
  const minVentasBeneficios = Math.min(...ventasValues, ...beneficiosValues);
  const maxVentasBeneficios = Math.max(...ventasValues, ...beneficiosValues);

  // Definir un rango más estrecho para el eje del Margen Operativo
  const margenNetoDomain = [
    Math.max(0, margenNetoMin - 5),
    Math.min(100, margenNetoMax + 5),
  ];
  const minYBeneficios = minVentasBeneficios >= 0 ? 0 : minVentasBeneficios;
  let maxYBeneficios;
  if (maxVentasBeneficios < 5000) {
    maxYBeneficios = maxVentasBeneficios + 500;
  } else if (maxVentasBeneficios < 10000) {
    maxYBeneficios = maxVentasBeneficios + 2000;
  } else if (maxVentasBeneficios < 40000) {
    maxYBeneficios = maxVentasBeneficios + 5000;
  } else {
    maxYBeneficios = maxVentasBeneficios + 10000;
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        sx={{
          fontSize: { xs: "14px", md: "18px" },
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Ventas - Ingresos - Margen Neto
      </Typography>
      <ComposedChart width={chartWidth} height={chartHeight} data={data}>
        <XAxis dataKey="name" tick={{ fontFamily: "Arial", fontSize: 13 }} />
        <YAxis
          yAxisId="margenNeto"
          orientation="left"
          domain={margenNetoDomain}
          tickFormatter={formatPercentages}
          tick={{ fontFamily: "Arial", fontSize: 13 }}
        />
        <YAxis
          yAxisId="ventasBeneficios"
          orientation="right"
          domain={[minYBeneficios, maxYBeneficios]}
          tickFormatter={formatThousandsNoDecimals}
          tick={{ fontFamily: "Arial", fontSize: 13 }}
        />
        <Tooltip
          formatter={(value, name, props) => {
            if (name === "MargenNeto") {
              return [formatPercentages(value), name];
            }
            return [formatThousands(value), name];
          }}
        />
        <Legend />
        <CartesianGrid stroke="#f5f5f5" />
        <Bar
          dataKey="Ventas"
          barSize={20}
          fill="#413ea0"
          yAxisId="ventasBeneficios"
        />
        <Bar
          dataKey="Beneficios"
          barSize={20}
          fill="#8884d8"
          yAxisId="ventasBeneficios"
        />
        <Line
          type="monotone"
          dataKey="MargenNeto"
          stroke="#ff7300"
          yAxisId="margenNeto"
        />
      </ComposedChart>
    </Box>
  );
};

export default VentasChart;
