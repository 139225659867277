import group from "../utils/images/group.svg";
import metric from "../utils/images/metric.svg";
import coin from "../utils/images/coin-hand.svg";
import security from "../utils/images/Security.svg";
import decision from "../utils/images/Decision.svg";
import chart from "../utils/images/Finance.svg";
import diverse from "../utils/images/diverse.svg";
import inflation from "../utils/images/inflation.svg";
import calendar from "../utils/images/calendar.svg";
import benefit from "../utils/images/benefits.svg";
import international from "../utils/images/international.svg";
import estability from "../utils/images/estability.svg";

export const InverSimpleTexto =
  "Inversimple nace con el objetivo de acercar el mundo de la inversión a todos los inversores argentinos.";
export const InverSimpleBox = [
  {
    title: "Acceso para Todos",
    text: "Gracias al arduo trabajo de emprendedores argentinos, se está redefiniendo la accesibilidad de las inversiones para el inversor minorista.",
    image: group,
  },
  {
    title: "Innovación Financiera",
    text: "En Inversimple, estamos impulsando una revolución en el mercado de capitales argentino, ofreciendo nuevas oportunidades de inversión.",
    image: metric,
  },
  {
    title: "Potencia Tu Dinero",
    text: "Descubre cómo puedes hacer que tu dinero trabaje más duro para ti con nuestras soluciones innovadoras y accesibles.",
    image: coin,
  },
];
export const porqueInversimple =
  "Nuestro objetivo no es dar asesoramiento financiero, sino proporcionar herramientas al pequeño inversor.";
export const porqueInversimpleItems = [
  {
    title: "Decisiones independientes",
    text: "Los inversores toman decisiones financieras por sí mismos utilizando nuestras herramientas.",
    image: decision,
  },
  {
    title: "Responsabilidad financiera",
    text: "Los inversores son responsables de evaluar  y asumir riesgos. Pudiendo manejar su cartera y maximizar ganancias.",
    image: chart,
  },
  {
    title: "Seguridad a largo plazo",
    text: "A través de la adopción de una filosofía de inversión correcta , los inversores pueden alcanzar la seguridad financiera a largo plazo.",
    image: security,
  },
];

export const porqueInvertir = [
  {
    title: "Diversificación ante la devaluación",
    text: "A pesar de la devaluación de la moneda argentina, algunos creen erróneamente que el dólar no pierde valor. Sin embargo, en los últimos 20 años, el dólar estadounidense ha perdido un 50% de su poder adquisitivo. La inversión puede ser una estrategia para proteger tu patrimonio de la depreciación monetaria.",
    image: diverse,
  },
  {
    title: "Protección contra la inflación",
    text: "La inflación en Estados Unidos alcanzó el 9% interanual en 2022, lo que significa que el dinero guardado bajo el colchón o en una caja de seguridad pierde un 9% de su poder adquisitivo cada año. Invertir puede ayudar a preservar y hacer crecer el valor de tu dinero frente a la erosión causada por la inflación.",
    image: inflation,
  },
  {
    title: "Estrategia a largo plazo",
    text: "Reconociendo que la percepción de estabilidad del dólar puede ser engañosa, es importante considerar la inversión como una forma de hacer crecer tus activos a largo plazo y aumentar tus ahorros.",
    image: calendar,
  },
];

export const porqueAcciones = [
  {
    text: "Nos brinda la posibilidad de tener acceso a la rentabilidad generada por una empresa en proporción a los recursos que invertimos. ",
  },
  {
    text: "Por menos de $3000, podemos ser dueños de empresas como Amazon o Google y formar parte de empresas con miles de millones de usuarios y ventas por 600.000 millones de dólares. ",
  },
  {
    text: "Al poder invertir cantidades tan pequeñas, tenemos mucha facilidad para diversificar nuestro riesgo comprando acciones de distintas empresas en diferentes países o industrias.",
  },
];

export const cedears =
  "Los Certificados de Depósito Argentinos (CEDEARs) son instrumentos financieros que pueden comprarse desde Argentina y que equivalen a una fracción de una acción que cotiza en el extranjero, en dólares. Al contar con uno de estos certificados, se tienen los mismos derechos que cualquier accionista extranjero.";

export const cedearsItems = [
  {
    title: "Oportunidades de Inversión Internacional",
    text: "Este certificado respalda acciones de empresas globales líderes, permitiendo a inversores argentinos participar en su rendimiento sin la necesidad de transacciones en moneda extranjera o cuentas en el extranjero.",
    image: international,
  },
  {
    title: "Beneficios",
    text: "Protección frente a la devaluación del peso argentino al estar vinculado al dólar. Exento del impuesto a las ganancias, todas las ganancias obtenidas a través de la inversión en CEDEARs están libres de impuestos.",
    image: benefit,
  },
  {
    title: "Seguridad y Estabilidad Financiera",
    text: "Los CEDEARs ofrecen una opción conservadora al estar resguardados del riesgo local, lo que proporciona estabilidad en el contexto económico del país.",
    image: estability,
  },
];
