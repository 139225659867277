import Image_home from "../utils/images/backgroundHome.jpg";

export const container = {
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3)), url(${Image_home})`,
  height: "100%",
  marginTop: "0px",
  backgroundSize: "cover",
  width: {
    xs: "100vw",
    sm: "100vw",
    md: "100%",
    lg: "100%",
    xl: "100%",
  },
  backgroundRepeat: "no-repeat",
};
