import { createContext, useState, useEffect } from "react";
import { firestore } from "./firebase";
import { collection, onSnapshot, doc } from "firebase/firestore";
import stocks from "../constants/stocks";

const PreciosContext = createContext();

const PreciosProvider = ({ children }) => {
  const [localStocks, setLocalStocks] = useState(stocks);
  const [dolarCCL, setDolarCCL] = useState(null);
  /*
   * @IMPORTANTE
   * Este bloque de código se descomenta cuando se pase a producción
   */
  useEffect(() => {
    const docId = "precios_diarios";
    const preciosAccionesRef = collection(firestore, "precios_acciones");
    const specificDocRef = doc(preciosAccionesRef, docId);
    const unsubscribe = onSnapshot(
      specificDocRef,
      (snapshot) => {
        if (snapshot.exists && !snapshot.metadata.hasPendingWrites) {
          const preciosAccionesData = snapshot.data();
          const newPreciosMap = Object.fromEntries(
            Object.entries(preciosAccionesData.precio_actual).map(
              ([key, value]) => [key, parseFloat(value).toFixed(2)]
            )
          );
          const newPrecioFinal = Object.fromEntries(
            Object.entries(preciosAccionesData.precio_final_dia).map(
              ([key, value]) => [key, parseFloat(value).toFixed(2)]
            )
          );
          actualizarPrecios(newPreciosMap, newPrecioFinal);
          console.log("Updated precios map:", newPreciosMap);
        } else {
          console.log("Document does not exist");
        }
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
    return () => {
      unsubscribe(); // Llama a la función de desuscripción al desmontar el componente
    };
  }, []);

  //IMPORTANTE COMENTAR HASTA ACA

  const actualizarPrecios = (preciosMap, precioFinal) => {
    // Funciones para transformar el precio de cada ticker según sea necesario
    const priceTransformations = {
      KO: (price, koBaPrice) => (koBaPrice * 5) / price, // Calcula el dolar CCL para KO
      GGAL: (price, galBaPrice) => (galBaPrice * 10) / price, // Calcula el dolar CCL para GGAL
    };

    // Calcular dolar CCL para KO y GGAL, obtener la suma de los resultados y calcular el promedio
    setDolarCCL(
      (
        Object.keys(priceTransformations).reduce((sum, ticker) => {
          const transformation = priceTransformations[ticker];
          return (
            sum + transformation(preciosMap[ticker], preciosMap[`${ticker}.BA`])
          );
        }, 0) / Object.keys(priceTransformations).length
      ).toFixed(0)
    );

    const updatedStocks = localStocks.map((stock) => {
      if (preciosMap.hasOwnProperty(stock.ticker)) {
        // Actualiza solo el precio de la acción
        return {
          ...stock,
          price: precioFinal[stock.ticker],
          actual_price: preciosMap[stock.ticker],
        };
      }

      return stock;
    });
    setLocalStocks(updatedStocks);
  };

  // hasta aca se comenta
  return (
    <PreciosContext.Provider
      value={{
        stocks: localStocks,
        setStocks: setLocalStocks,
        dolarCCL: dolarCCL,
      }}
    >
      {children}
    </PreciosContext.Provider>
  );
};

export { PreciosProvider, PreciosContext };
