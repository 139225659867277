import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import * as title from "../../constants/titles_home.js";
import * as styles from "../../styles/Home.js";
import imageCedear from "../../utils/images/CedearImage.png";
import arrow from "../../utils/images/fi_arrow-down-left.svg";
import Item from "../Item.js";

const Cedears = (props) => {
  const theme = useTheme();
  const { xs, sm } = theme.breakpoints.values;

  // Determinar el tamaño de la imagen según los breakpoints del tema
  let imageSize;
  if ((window.innerWidth = sm)) {
    imageSize = "50vw";
  } else {
    imageSize = "15vw";
  }
  return (
    <Box sx={styles.styleContenedorCedears}>
      <Box sx={{ flex: 0.5 }}>
        <Typography sx={styles.styleCedearsTitle}>CEDEARS</Typography>
        <Typography sx={styles.styleCedearsText}>{title.cedears}</Typography>
        <Box sx={styles.styleContenedorCedearsImage}>
          <img
            src={imageCedear}
            alt={"Cedear"}
            style={{ width: imageSize, height: "auto" }}
          />
          <Box sx={styles.styleCedearsButtonContainer}>
            <Button sx={styles.styleCedearsButton} onClick={props.handleClick}>
              <Typography sx={styles.styleCedearButtonText}>
                Conoce más
              </Typography>
              <Box sx={styles.styleCedearButtonIcon}>
                <img src={arrow} alt={title} />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 0.5, marginTop: "" }}>
        <Box sx={styles.styleContenedorCedearsItem}>
          {title.cedearsItems.map((data, index) => (
            <Item
              key={index}
              title={data.title}
              text={data.text}
              icon={data.image}
              colorBox={"#FFFF"}
              colorText={"#FFFF"}
              colorIcon={"#2A4887"}
            ></Item>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Cedears;
