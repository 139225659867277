import React, { useState } from "react";
import {
  Typography,
  Paper,
  DialogContent,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  conservador,
  moderado,
  arriesgado,
} from "../../constants/portfolio.js";
import DonutChart from "../chart/CarteraChart.js";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const DialogPorfolio = ({ text, value }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCartera, setSelectedCartera] = useState(null);

  const handleClickOpen = (cartera, index) => {
    setSelectedCartera(cartera);
    setOpenDialog(index);
  };

  const handleClose = () => {
    setOpenDialog(null);
  };
  return (
    <div>
      <Typography sx={{ fontSize: "14px", mb: 2 }}>Tipos de Cartera</Typography>
      {[conservador, moderado, arriesgado].map((cartera, index) => (
        <Paper
          key={index}
          sx={{ padding: 2, marginBottom: 1, cursor: "pointer" }}
          onClick={() => handleClickOpen(cartera, index)}
        >
          <Typography sx={{ fontSize: "14px" }}>{cartera.heading}</Typography>
        </Paper>
      ))}
      <Dialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={openDialog !== null && selectedCartera !== null}
        sx={{
          "& .MuiDialog-paper": {
            width: { xs: "100%", sm: "90%", md: "60%" },
            maxWidth: "none",
          },
        }}
      >
        {selectedCartera && (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">
              {selectedCartera.heading}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "justify",
                }}
              >
                {selectedCartera.panels && (
                  <Typography gutterBottom>
                    {selectedCartera.panels[0].content}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "100%", sm: "100%", md: "70%" },
                    }}
                  >
                    {selectedCartera.panels &&
                      selectedCartera.panels.slice(1).map((panel, idx) => (
                        <Typography key={idx + 1} gutterBottom>
                          {panel.content}
                        </Typography>
                      ))}
                  </Box>
                  <DonutChart profile={selectedCartera.type} />
                </Box>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default DialogPorfolio;
