import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../styles/Card";

const Card = ({ title, text, icon, color, colorText }) => {
  return (
    <Box sx={styles.container(color)}>
      <Box>
        <img src={icon} alt={title} style={{ width: "50px", height: "50px" }} />
        <Typography sx={styles.containerTitle(colorText)}>{title}</Typography>
        <Typography sx={styles.containerText(colorText)}>{text}</Typography>
      </Box>
    </Box>
  );
};

export default Card;
