import { Box, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextFieldNum from "../TextFieldNum";
import * as styles from "../../styles/TextFieldNum";

export default function FiltersTable({
  companyFilter,
  setCompanyFilter,
  selectedRatios,
  ratioFilters,
  handleRatioSelectionChange,
  handleRemoveFilter,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "2vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginRight: "2vh",
          width: "15vw",
        }}
      >
        <TextField
          label="Empresa"
          value={companyFilter}
          onChange={(e) => setCompanyFilter(e.target.value)}
          sx={{
            ...styles.TextFieldStyle,
            "& input": {
              marginTop: "-8px",
            },
          }}
          InputLabelProps={{
            sx: { marginTop: "-7px" },
          }}
        />
      </Box>
      {Object.keys(selectedRatios).map(
        (ratio) =>
          selectedRatios[ratio] &&
          (ratio === "PER" || ratio === "EVtoFCF" || ratio === "PtoSales") && (
            <Box
              key={ratio}
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "4vh",
                width: "15vw",
              }}
            >
              <Box sx={{ paddingTop: "20px" }}>
                <TextFieldNum
                  formData={ratioFilters[ratio].max}
                  handleChange={(e) => handleRatioSelectionChange(e, ratio)}
                  errors={""}
                  label={`Máximo ${ratio}`}
                  name="max"
                />
              </Box>
              {/* <IconButton
                aria-label="Quitar filtro"
                onClick={() => handleRemoveFilter(ratio)}
                size="small"
              >
                <DeleteIcon sx={{ color: "#FFFFFF" }} />
              </IconButton> */}
            </Box>
          )
      )}
      {Object.keys(selectedRatios).map(
        (ratio) =>
          selectedRatios[ratio] &&
          ratio === "MgNet" && (
            <Box
              key={ratio}
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "2vh",
                width: "15vw",
              }}
            >
              <Box sx={{ paddingTop: "20px" }}>
                <TextFieldNum
                  formData={ratioFilters[ratio].min}
                  handleChange={(e) => handleRatioSelectionChange(e, ratio)}
                  errors={""}
                  label={`Mínimo ${ratio}`}
                  name="min"
                />
              </Box>
              {/* <IconButton
                aria-label="Quitar filtro"
                onClick={() => handleRemoveFilter(ratio)}
                size="small"
              >
                <DeleteIcon sx={{ color: "#FFFFFF" }} />
              </IconButton> */}
            </Box>
          )
      )}
    </Box>
  );
}
