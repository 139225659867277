import { useState } from "react";
import FrontCard from "./FrontCard";
import MoreFinancial from "./MoreFinancial";
import React, { useRef } from "react";

const MediaCard = ({ image, stock, isAtTop, onMoveToTopClick }) => {
  const [front, setFront] = useState(true);
  const moreFinancialRef = useRef(null);

  if (front) {
    return (
      <FrontCard
        isAtTop={isAtTop}
        onMoveToTopClick={onMoveToTopClick}
        front={front}
        setFront={setFront}
        image={image}
        stock={stock}
        ticker={stock.ticker}
        description={stock.description}
      ></FrontCard>
    );
  } else {
    return (
      <MoreFinancial
        image={image}
        stock={stock}
        ref={moreFinancialRef}
        setFront={setFront}
        fixedPosition={false}
      ></MoreFinancial>
    );
  }
};

export default MediaCard;
