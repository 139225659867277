export const containerBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: 1,
  gap: {
    xs: 0,
    sm: 2,
    md: 3,
    lg: 10,
    xl: 10,
  },
  width: "100%",
};

export const containerSelect = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: {
    xs: 0.7,
    sm: 0.8,
    md: 0.8,
    lg: 1,
    xl: 1,
  },
  maxWidth: {
    xs: "50%",
    sm: "60%",
    md: "100%",
    lg: "100%",
    xl: "100%",
  },
};

export const containerTextField = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: {
    xs: 0.4,
    sm: 0.5,
    md: 0.5,
    lg: 0.5,
    xl: 0.5,
  },
};

export const containerButton = {
  flex: {
    xs: 0.1,
    sm: 0.1,
    md: 0.2,
  },
  marginBottom: "25px",
};

export const selectStyle = {
  marginRight: "0px",
  backgroundColor: "white",
  borderRadius: 2,
  height: "40px",
  alingItems: "center",
  "& fieldset": {
    border: "none",
  },
  width: "90%",
  fontSize: { xs: "12px", md: "16px" },
};
