export const TextFieldStyle = {
  marginRight: "50px",
  backgroundColor: "white",
  borderRadius: 2,
  height: "40px",

  "& fieldset": {
    border: "none",
  },

  "& .MuiInputLabel-root.Mui-focused": {
    color: "#2A4887",
    marginTop: "1px",
  },
  width: "100%",
};
