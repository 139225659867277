import React, { useEffect, useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../styles/Home.js";
import * as title from "../constants/titles_home.js";
import Card from "../components/Card.js";
import ObjetivoBox from "../components/home/Objetivo.js";
import InvertirBox from "../components/home/Invertir.js";
import AccionesBox from "../components/home/Acciones.js";
import CedearsBox from "../components/home/Cedears.js";
import CedearsMobile from "../components/home/Cedears_mobile.js";
import MobileStepperExample from "../components/home/Stepper.js";

export default function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const handleOpenCedears = () => {
    window.location.href = "/Cedears"; // Abre la URL en una nueva pestaña cuando se toca cualquiera de los ratios
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 780);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    // INVERSIMPLE

    <Box sx={styles.containerStyle}>
      <Box sx={styles.containerInver}>
        <Box>
          <Typography sx={styles.styleInversimple}>
            <span style={{ fontWeight: "bold" }}>INVER</span>
            <span style={{ fontWeight: "lighter" }}>SIMPLE</span>
          </Typography>
          <Typography variant="h6" sx={styles.styleInverTexto}>
            {title.InverSimpleTexto}
          </Typography>
        </Box>
        {!isMobile ? (
          <Box sx={styles.styleInverBox}>
            {title.InverSimpleBox.map((data, index) => (
              <Card
                key={index}
                title={data.title}
                text={data.text}
                icon={data.image}
                color={
                  index === title.InverSimpleBox.length - 1
                    ? "#355CAE"
                    : "#FFFFFF"
                }
                colorText={
                  index === title.InverSimpleBox.length - 1 ? "#FFFFFF" : "#000"
                }
              />
            ))}
          </Box>
        ) : (
          <Box sx={styles.styleInverBox}>
            <MobileStepperExample
              InverSimpleBox={title.InverSimpleBox}
              isMobile={isMobile}
            ></MobileStepperExample>
          </Box>
        )}
      </Box>
      <Box>
        {/* Porque inversimple, porque invertir, porque acciones */}
        <ObjetivoBox></ObjetivoBox>
        <InvertirBox></InvertirBox>
        <AccionesBox></AccionesBox>
        {/* Que son los cedears, ventajas de los cedears */}
        {isMobile ? (
          <CedearsMobile handleClick={handleOpenCedears} />
        ) : (
          <CedearsBox handleClick={handleOpenCedears} />
        )}
      </Box>
    </Box>
  );
}
