import {
  Box,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import * as styles from "../styles/Ratios.js";

function RatiosCard({
  text,
  buttonText,
  dialogTittle,
  dialogText,
  dialogBold,
  textEjemplo,
  tooltipTittle,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={styles.dialogContainerStyle}>
      <Box sx={styles.boxDialogStyle}>
        <Tooltip title={tooltipTittle} placement="top" arrow>
          <Typography sx={styles.dialogTittleStyle}>{dialogTittle}</Typography>
        </Tooltip>
        <Typography sx={styles.textCardStyle}>{text}</Typography>
      </Box>
      <Button onClick={handleOpen} sx={styles.buttonStyle} variant="contained">
        <Typography sx={styles.buttonTextStyle}>{buttonText}</Typography>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={styles.dialogStyle}>{dialogTittle}</DialogTitle>
        <DialogContent>
          <Typography>
            <div dangerouslySetInnerHTML={{ __html: dialogText }} />
            <span style={{ fontWeight: "bold" }}>{dialogBold}</span>
          </Typography>
          <Typography sx={{ marginTop: "20px" }}>
            <span style={{ fontWeight: "bold" }}>Ejemplo:</span>
            {textEjemplo}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const Ratios = () => {
  const [textoActual, setTextoActual] = useState(1);
  const circle1 = {
    marginRight: "12px",
    marginLeft: "12px",
    color: textoActual === 1 ? "#2a4886" : "#C5C3C3",
  };
  const circle2 = {
    marginRight: "12px",
    marginLeft: "12px",
    color: textoActual === 2 ? "#2a4886" : "#C5C3C3",
  };
  const circle3 = {
    marginRight: "12px",
    marginLeft: "12px",
    color: textoActual === 3 ? "#2a4886" : "#C5C3C3",
  };

  const cambiarTexto = (nuevoTexto) => {
    setTextoActual(nuevoTexto);
  };

  return (
    <Box sx={styles.backgroundStyle}>
      <Box sx={styles.containerStyle}>
        <Box sx={styles.boxStyle}>
          <Collapse in={textoActual === 1} timeout={0}>
            <Box sx={styles.boxTextStyle}>
              <Typography sx={styles.tittleText}>¿QUÉ SON?</Typography>
              <Typography sx={styles.textStyle}>{styles.queSonText}</Typography>
            </Box>
          </Collapse>
          <Collapse in={textoActual === 2} timeout={0}>
            <Box sx={styles.boxTextStyle}>
              <Typography sx={styles.tittleText}>¿PARA QUÉ SIRVEN?</Typography>
              <Typography sx={styles.textStyle}>
                {styles.paraQueSirvenText}
              </Typography>
            </Box>
          </Collapse>
          <Collapse in={textoActual === 3} timeout={0}>
            <Box sx={styles.boxTextStyle}>
              <Typography sx={styles.tittleText}>IMPORTANCIA</Typography>
              <Typography sx={styles.textStyle}>
                {styles.importanciaText}
              </Typography>
            </Box>
          </Collapse>
          <Box sx={styles.boxCircle}>
            <IconButton onClick={() => cambiarTexto(1)}>
              <CircleRoundedIcon sx={circle1} />
            </IconButton>
            <IconButton onClick={() => cambiarTexto(2)}>
              <CircleRoundedIcon sx={circle2} />
            </IconButton>
            <IconButton onClick={() => cambiarTexto(3)}>
              <CircleRoundedIcon sx={circle3} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Typography variant="h3" sx={styles.ratiosTittle}>
        Principales Ratios Financieros
      </Typography>
      <Box sx={styles.ratiosBox}>
        <RatiosCard
          text={
            "Compara las ganancias de una empresa con su valor de mercado. Indica el número de años que el inversor tardaría en recuperar su inversión."
          }
          buttonText={"Capit de Mercado / Beneficios Netos"}
          dialogTittle={"PER"}
          tooltipTittle={"Price to Earnings (Precio sobre Beneficios)"}
          dialogText={
            "Capitalizacion de Mercado: Cuanto vale la empresa si uno comprara todas las acciones del mercado<br></br>Beneficios Netos: Cuanto gana la empresa luego de restar todos sus gastos de operaciones<br></br>"
          }
          dialogBold={"PER = Capitalización de Mercado / Beneficios Netos"}
          textEjemplo={
            " si una acción cotiza a $30 y la empresa gana $2 por acción, tendríamos un PER de 15 (30/2), el inversor tardaría 15 años en recuperar su inversión."
          }
        ></RatiosCard>
        <RatiosCard
          text={
            "Compara el valor de mercado con las ventas. Es util para evaluar empresas con margenes operativos muy ajustados y que, por ende, dependen de crecer sus ventas para que crezcan sus beneficios."
          }
          buttonText={"Capit de Mercado / Ventas Totales"}
          dialogTittle={"P/Sales"}
          tooltipTittle={"Price to Sales (Precio sobre Ventas)"}
          dialogText={
            "Capitalizacion de Mercado: Cuanto vale la empresa si uno comprara todas las acciones del mercado<br></br>Ventas Totales: Incluye todas las ventas que la empresa consigue en el periodo fiscal<br></br>"
          }
          dialogBold={"P/Sales = Capitalizacion de Mercado / Ventas Totales"}
          textEjemplo={
            " si una empresa vende $100 dolares y su valor de mercado es $50, su P/Sales sera de 2, lo que significa que la empresa vende 2 veces su valor de mercado por año."
          }
        ></RatiosCard>
        <RatiosCard
          text={
            "Es una version mas precisa que el PER, utiliza el enterprise value, lo que permite ponderar la cantidad de deuda. Es un ratio que pondera, al mismo tiempo, la situacion financiera de la empresa y sus beneficios."
          }
          buttonText={"(Capit Mercado + Deuda) / F. Caja Libre"}
          dialogTittle={"EV/FCF"}
          tooltipTittle={"Enterprise Value to Free Cash Flow (EV sobre FCF)"}
          dialogText={
            "Capitalizacion de Mercado: Cuanto vale la empresa si uno comprara todas las acciones del mercado<br></br>Deuda Neta: Es la deuda de la empresa una vez descontados el efectivo y equivalentes<br></br>Flujo de Caja Libre: Resultado Neto + Depreciaciones & Amortizaciones - Gastos de Capital<br></br>"
          }
          dialogBold={
            "EV/FCF = (Capitalización de Mercado - Deuda Neta) / Flujo de Caja Libre"
          }
          textEjemplo={
            " si una empresa vale $1.000 por mercado y tiene deuda por $500, su EV sera de $1.500. Si tuviese un FCF de $100, estaria cotizando a EC/FCF de 15 veces."
          }
        ></RatiosCard>
        <RatiosCard
          text={
            "Es la relación entre el valor de mercado de la empresa y su patrimonio neto (Activos - Pasivos). Un P/BV menor a 1 indica que el patrimonio de la empresa es mayor que su valor de mercado."
          }
          buttonText={"Capit de Mercado / Patrimonio Neto"}
          dialogTittle={"P/BV"}
          tooltipTittle={"Price to Book Value (Precio sobre Valor en Libros)"}
          dialogText={
            "Capitalizacion de Mercado: Cuanto vale la empresa si uno comprara todas las acciones del mercado<br></br>Book Value: Es igual al Patrimonio Neto, Activos menos Pasivos<br></br>"
          }
          dialogBold={"P/BV = Capitalización de Mercado / Patrimonio Neto"}
          textEjemplo={
            " si una empresa vale $900 por mercado, tiene deuda de $200 y activos por $1.200, su P/BV es de 0.9 --> 900/(1.200-200)."
          }
        ></RatiosCard>
        <RatiosCard
          text={
            "Compara los flujos de caja de una empresa con su valor de mercado. Indica el número de años (de flujo de caja) que el inversor tardaría en recuperar su inversión."
          }
          buttonText={"Capit de Mercado / Flujo de Caja Libre"}
          dialogTittle={"P/FCF"}
          tooltipTittle={
            "Price to Free Cash Flow (Precio sobre Flujo Caja Libre)"
          }
          dialogText={
            "Capitalizacion de Mercado: Cuanto vale la empresa si uno comprara todas las acciones del mercado<br></br>Flujo de Caja Libre: Resultado Neto + Depreciaciones & Amortizaciones - Gastos de Capital<br></br>"
          }
          dialogBold={"P/FCF = Capitalización de Mercado / Flujo de Caja Libre"}
          textEjemplo={
            " si una empresa vale $500, tiene beneficios por $40, depreciaciones por $20 y gastos capital por $10, su P/FCF sera de 10 --> 500/(40+20-10)"
          }
        ></RatiosCard>
        <RatiosCard
          text={
            "Es la relacion entre el EV de la empresa y el EBITDA que genera. Pondera tanto la deuda que tiene la empresa como los gastos de depreciacion y amortizacion. "
          }
          buttonText={"(Capit Mercado + Deuda) / EBITDA"}
          dialogTittle={"EV/EBITDA"}
          tooltipTittle={
            "Enterprise Value to EBITDA (Valor Empresarial sobre EBITDA)"
          }
          dialogText={
            "Capitalizacion de Mercado: Cuanto vale la empresa si uno comprara todas las acciones del mercado<br></br>Deuda Neta: Es la deuda de la empresa una vez descontados el efectivo y equivalentes<br></br>EBITDA:Es la suma de los ingresos operativos mas las Depreciaciones y Amortizaciones<br></br>"
          }
          dialogBold={
            "EV/EBITDA = (Capitalización de Mercado - Deuda Neta) / EBITDA"
          }
          textEjemplo={
            " si una empresa vale $2.000 por mercado, tiene efectivo por $600 y deuda por $100, su EV sera de $2.500 (2000+600-100). Si su EBITDA fuera de $100, su EV/EBITDA seria de 25."
          }
        ></RatiosCard>
      </Box>
    </Box>
  );
};

export default Ratios;
