import React from "react";
import { Box } from "@mui/material";
import Tag from "../Tag";
import Table from "../Table";
import UpIcon from "../../utils/images/arrow-thick-top.svg";
import DownIcon from "../../utils/images/arrow-thick-bottom.svg";

const TablePorfolioMobile = ({
  styles,
  acciones,
  functions,
  stocks,
  dolarCCL,
  handleEliminarAccion,
}) => {
  return (
    <Box sx={styles.containerTable}>
      <Table
        columns={[
          { id: "ticker", label: "Código" },
          { id: "valorUSD", label: "Valor" },
          { id: "variacion", label: "Variación" },
        ]}
        rows={acciones.map((accion, index) => {
          const cantidadTexto = accion.cantidad > 1 ? "acciones" : "acción";
          const variacion = functions
            .calcularVariacion(accion, stocks)
            .toLocaleString("es-ES", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          return {
            ticker: accion.ticker,
            name: (
              <div>
                <div>
                  {stocks.find((stock) => stock.ticker === accion.ticker)?.name}
                </div>
                <div
                  style={{ fontSize: "0.8em", color: "gray" }}
                >{`${accion.cantidad} ${cantidadTexto}`}</div>
              </div>
            ),
            cantidad: accion.cantidad,
            valorUSD: `$ ${functions.valorAccionUSD(
              accion.ticker,
              accion.cantidad,
              stocks,
              dolarCCL
            )}`,
            variacion: (
              <Tag
                text={`  ${Math.abs(variacion).toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} %`}
                colorChoice={
                  variacion > 0 ? "green" : variacion < 0 ? "red" : "default"
                }
              >
                {variacion > 0 && (
                  <img
                    src={UpIcon}
                    alt="Flecha hacia arriba"
                    style={{
                      width: "1em",
                      height: "0.8em",
                      fill: "green",
                    }}
                  />
                )}
                {variacion < 0 && (
                  <img
                    src={DownIcon}
                    alt="Flecha hacia abajo"
                    style={{
                      width: "1em",
                      height: "0.8em",
                    }}
                  />
                )}
              </Tag>
            ),
          };
        })}
        eliminarAccion={handleEliminarAccion}
      />
    </Box>
  );
};
export default TablePorfolioMobile;
