export const styleButtonContainer = {
  position: "relative",
  bottom: 0,
  right: { xs: "-4px", sm: "0px" },
};
export const styleButton = {
  borderRadius: "8px",
  border: 0,
  height: { xs: "40px", sm: "50px", md: "40px" },
  width: "100%",
  backgroundColor: "#2A4887",
  cursor: "pointer", // Cambia el cursor al pasar el mouse sobre el botón
  color: "#FFFF",
  "&:hover": { backgroundColor: "#355CAE" },
};
export const styleButtonText = {
  fontSize: { xs: "12px", sm: "12px", md: "13px", lg: "14px", xl: "16px" },
  textTransform: "none",
};
