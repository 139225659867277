import { Box, Button, TextField, Typography } from "@mui/material";
import * as button from "../../styles/button";
import * as styles from "../../styles/TextField";

const TextFieldCodigo = ({
  codigoPortfolio,
  handleChangeCodigoPortfolio,
  handleSubmitCodigoPortfolio,
}) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.text}>
        Si ya tienes una cartera creada ingresa el código
      </Typography>
      <Box sx={styles.textFieldContainer}>
        <TextField
          id="codigoPortfolio"
          label=""
          value={codigoPortfolio}
          onChange={handleChangeCodigoPortfolio}
          sx={styles.textField}
        />
        <Button sx={button.styleButton} onClick={handleSubmitCodigoPortfolio}>
          <Typography sx={button.styleButtonText}>Ingresar Código</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default TextFieldCodigo;
