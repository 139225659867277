export const containerStyle = {
  backgroundColor: "rgba(255, 255, 255, 0.72)",
  display: "flex",
  marginLeft: { xs: "10px", sm: "20px", md: "50px" },
  marginRight: { xs: "10px", sm: "20px", md: "50px" },
  borderRadius: 5,
  paddingInline: { xs: "10px", sm: "20px", md: "40px" },
  paddingBlock: "30px",
  flex: 1,
  gap: { xs: "none", sm: "none", md: "none", lg: "6rem" },
  marginTop: "30px",
  marginBottom: "50px",
};

export const containerTable = {
  display: "flex",
  flexDirection: "column",
  marginTop: "0.5rem",
};

export const containerCard = {
  display: "flex",
  flexDirection: "column",
  gap: 2,
  width: "25px",
};

export const containerDialog = {
  display: "flex",
  flexDirection: "column",
  marginTop: "3rem",
};

export const styleCardContainer = {
  flex: "0 0 auto", // Ancho responsivo
  backgroundColor: "#FFFF",
  boxShadow: "0 13px 19px rgba(0, 0, 0, 0.07)",
  display: "grid",
  flexDirection: "row",
  paddingInline: {
    xs: "20px",
    sm: "30px",
    md: "30px",
    lg: "20px",
    xl: "30px",
  },
  paddingBlock: "3vh",
  borderRadius: 3,
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 2,
};

export const styleBoxContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 1,
  flex: 0.6,
  width: "100%",
};

const colorMap = {
  green: {
    backgroundColor: "#e5f4ea",
    text: "#549a6b",
  },
  red: {
    backgroundColor: "#fce8e6",
    text: "#a50e13",
  },
  default: {
    backgroundColor: "#F1F1F1",
    text: "#000",
  },
};
export const styleBox = (color) => ({
  width: { xs: "100%", md: "90%" }, // Ancho responsivo
  height: "fit-content",
  backgroundColor: colorMap[color]
    ? colorMap[color].backgroundColor
    : colorMap.default.backgroundColor,
  display: "flex",
  flexDirection: "column",
  paddingBlock: "2vh",
  borderRadius: 4,
  color: colorMap[color] ? colorMap[color].text : colorMap.default.text,
});
export const styleBoxText = {
  fontSize: { xs: "11px", md: "12px", lg: "14px", xl: "14px" },
  textTransform: "uppercase",
};
export const styleBoxValue = {
  fontSize: { xs: "13px", sm: "14px", md: "16px", lg: "18px", xl: "18px" },
  textTransform: "uppercase",
  marginLeft: 2,
};

export const styleCardChart = {
  flex: "1 1 auto", // Ancho responsivo
  height: "fit-content",
  backgroundColor: "#FFFF",
  boxShadow: "0 13px 19px rgba(0, 0, 0, 0.07)",
  display: "flex",
  paddingInline: {
    xs: "20px",
    sm: "30px",
    md: "30px",
    lg: "10px",
    xl: "30px",
  },
  borderRadius: 2,
  justifyContent: "center",
  flexDirection: "column",
  alingItems: "center",
  gap: 2,
};

export const styleBoxChart = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: 2,
  paddingBlock: 3,
};

export const Card = {
  paddingBlock: 10,
};

export const Dialog = {
  paddingTop: 7,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  flexDirection: "column",
};
