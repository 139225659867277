import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import MediaCard from "../components/cedears/MediaCard.js";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as styles from "../styles/Cedears.js";
import { PreciosContext } from "../hooks/PricesContext.js";

const Cedears = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filterByName, setFilterByName] = useState("");

  const [selectedTickers, setSelectedTickers] = useState([]);

  const { stocks } = useContext(PreciosContext);

  const handleMoveToTopClick = (ticker) => {
    if (selectedTickers.includes(ticker)) {
      setSelectedTickers(selectedTickers.filter((t) => t !== ticker));
    } else {
      setSelectedTickers([...selectedTickers, ticker]);
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detectar si estamos en una pantalla pequeña (móvil)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Puedes ajustar este valor según tus necesidades
    };

    // Configurar el evento de escucha del tamaño de la pantalla
    window.addEventListener("resize", handleResize);

    // Llamar al manejador de redimensionamiento para establecer el estado inicial
    handleResize();

    // Limpiar el evento de escucha al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={styles.containerPrincipal}>
      <Box sx={styles.myStyle2}>
        <Box>
          <TextField
            label="Nombre"
            value={filterByName}
            onChange={(e) => setFilterByName(e.target.value)}
            sx={{
              ...styles.styleTextField,
              "& input": {
                marginTop: "-8px",
              },
            }}
            InputLabelProps={{
              sx: { marginTop: "-7px" },
            }}
          />
        </Box>
        <Box>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("")}
          >
            Todas
          </Button>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("Tecnologico")}
          >
            Tecno
          </Button>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("Financiero")}
          >
            {isMobile ? "Finan" : "Financiero"}
          </Button>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("Consumo")}
          >
            {isMobile ? "Cons" : "Consumo"}
          </Button>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("Commodity")}
          >
            {isMobile ? "O&G" : "Commodity"}
          </Button>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("Industrial")}
          >
            {isMobile ? "Indus" : "Industrial"}
          </Button>
          <Button
            sx={styles.styleButton}
            onClick={() => setSelectedCategory("Farmaceutico")}
          >
            {isMobile ? "Phar" : "Farmaceutico"}
          </Button>
        </Box>
      </Box>
      <Box s sx={styles.styleContainer}>
        {stocks
          .sort((a, b) => {
            const aIsAtTop = selectedTickers.includes(a.ticker);
            const bIsAtTop = selectedTickers.includes(b.ticker);

            if (aIsAtTop && !bIsAtTop) return -1;
            if (!aIsAtTop && bIsAtTop) return 1;
            return 0;
          })
          .filter(
            (stock) =>
              (selectedCategory === "" ||
                stock.category === selectedCategory) &&
              (filterByName === "" ||
                stock.name.toLowerCase().includes(filterByName.toLowerCase()))
          )
          .map((stock) => (
            <MediaCard
              key={stock.ticker}
              image={stock.image}
              stock={stock}
              onMoveToTopClick={() => handleMoveToTopClick(stock.ticker)}
              isAtTop={selectedTickers.includes(stock.ticker)}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Cedears;
