import { Box, Typography } from "@mui/material";
import * as styles from "../../styles/MoreFinancial";
import Tag from "../Tag";

const CustomBox = ({ data, sx }) => {
  return (
    <Box
      sx={{
        ...styles.containerDatos,
        ...sx,
      }}
    >
      {data.map((item) => (
        <Box
          key={item.label}
          sx={{
            ...styles.boxPriceFullScreen,
          }}
        >
          <Typography sx={styles.priceStyle}>{item.label}</Typography>
          {item.tag && (
            <Tag
              text={item.tag.text}
              colorChoice={item.tag.colorChoice}
              sx={{
                ...styles.priceStyle,
              }}
            />
          )}
          {item.value && (
            <Typography
              sx={{
                ...styles.priceStyle,
                ...(item.label === "Upside/Downside"
                  ? { color: item.color }
                  : {}),
              }}
            >
              ${item.value}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CustomBox;
