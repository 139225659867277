import { useState } from "react";
import { Paper, Box, Typography, MobileStepper } from "@mui/material";
import { useSwipeable } from "react-swipeable";

const Stepper = ({ Step1Component, Step2Component, stock }) => {
  const [activeStep, setActiveStep] = useState(0);
  const totalSteps = 2;

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, totalSteps - 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handleBack(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const CurrentStepComponent =
    activeStep === 0 ? Step1Component : Step2Component;

  return (
    <div {...handlers} style={{ width: "100%" }}>
      <Paper
        sx={{
          width: { xs: "100%" },
          background: "#0000",
          boxShadow: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            // Aquí puedes definir estilos específicos para cada paso si lo deseas.
            paddingLeft: "3vh",
          }}
        >
          <CurrentStepComponent stock={stock} />
        </Box>
      </Paper>

      <MobileStepper
        variant="dots"
        steps={totalSteps}
        position="static"
        activeStep={activeStep}
        style={{
          background: "transparent",
          color: "white",
          justifyContent: "center",
        }}
        sx={{
          "& .MuiMobileStepper-dot.MuiMobileStepper-dotActive": {
            backgroundColor: "#31559f", // Cambia el color del punto activo
          },
        }}
      />
    </div>
  );
};

export default Stepper;
