import React, { useState } from "react";
import { MobileStepper, Paper, Typography, Box } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import * as styles from "../../styles/Card";

const MobileStepperWithCards = ({ InverSimpleBox, isMobile }) => {
  const [activeStep, setActiveStep] = useState(0);
  const title = InverSimpleBox[activeStep].title;
  const text = InverSimpleBox[activeStep].text;
  const icon = InverSimpleBox[activeStep].image;

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, InverSimpleBox.length - 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handleBack(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} style={{ width: "100%" }}>
      <Paper
        sx={{
          width: { xs: "100%" },
          background: "#0000",
          boxShadow: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            ...styles.containerMobile(
              activeStep === InverSimpleBox.length - 1 ? "#355CAE" : "#FFFFFF"
            ),
            paddingLeft: "3vh",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <img
              src={icon}
              alt={title}
              style={{ width: "50px", height: "50px" }}
            />
            <Typography
              sx={{
                ...styles.containerTitle(
                  activeStep === InverSimpleBox.length - 1 ? "#FFFFFF" : "#000"
                ),
                paddingTop: 0,
                paddingLeft: 2,
              }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            sx={{
              ...styles.containerText(
                activeStep === InverSimpleBox.length - 1 ? "#FFFFFF" : "#000"
              ),
              paddingTop: "1rem",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Paper>

      <MobileStepper
        variant="dots"
        steps={InverSimpleBox.length}
        position="static"
        activeStep={activeStep}
        style={{
          background: "transparent",
          color: "white",
          justifyContent: "center",
        }}
        sx={{
          "& .MuiMobileStepper-dot.MuiMobileStepper-dotActive": {
            backgroundColor: "#FFFF", // Cambia el color del punto activo
          },
        }}
      />
    </div>
  );
};

export default MobileStepperWithCards;
