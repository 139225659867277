export const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Empresa",
  },
  {
    id: "Precio",
    numeric: true,
    disablePadding: false,
    label: "Precio",
  },
  {
    id: "Variacion",
    numeric: true,
    disablePadding: false,
    label: "Variacion",
  },
  {
    id: "Var1Y",
    numeric: true,
    disablePadding: false,
    label: "Var1Y",
  },
  {
    id: "Var5Y",
    numeric: true,
    disablePadding: false,
    label: "Var5Y",
  },
  {
    id: "PER",
    numeric: true,
    disablePadding: false,
    label: "PER",
  },
  {
    id: "EVtoFCF",
    numeric: true,
    disablePadding: false,
    label: "EV/FCF",
  },
  {
    id: "PtoSales",
    numeric: true,
    disablePadding: false,
    label: "P/Sales",
  },
  {
    id: "MgNet",
    numeric: true,
    disablePadding: false,
    label: "MgNet",
  },
];
