export const containerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  minWidth: {
    xs: "100vw",
    sm: "100vw",
    md: "100%",
    lg: "100%",
    xl: "100%",
  },
};

export const containerInver = {
  display: "flex",
  flexDirection: "column",
  height: { sm: "80vh", md: "70vh", lg: "53vh", xl: "42vh" },
  paddingBottom: "7vh",
  marginInline: { xs: "7vw", sm: "5vw", md: "5vw" },
  paddingTop: { xs: "2vh", sm: "2vh", md: "4vh" },
  flex: 1,
};

export const styleInversimple = {
  color: "white",
  marginTop: { xs: "4vh", md: "6vh" },
  fontSize: { xs: "4vh", sm: "32px", md: "34px", lg: "40px", xl: "48px" },
};
export const styleInverTexto = {
  color: "white",
  marginTop: { xs: "3vh", md: "25px" },
  maxWidth: { sm: "600px", md: "600px", lg: "700px", xl: "700px" },
  fontSize: { xs: "16px", sm: "18px", md: "22px", lg: "20px", xl: "23px" },
  marginRight: { xs: "3vh", sm: "70px" },
};
export const styleInverBox = {
  display: "flex",
  gap: { xs: "12%", sm: "7%", md: "10%", lg: "12%", xl: "12%" },
  alingItems: "center",
  marginTop: "7vh",
  justifyContent: "center",
};
export const styleContenedorTextcard = {
  background: "linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.4));",
  display: "flex",
  flexDirection: "column",
  paddingTop: "4%",
  paddingBottom: { xs: "5vh", sm: "4vw", md: "3vw", lg: "5vw", xl: "7vh" },
  paddingInline: { xs: "7vw", sm: "7vw", md: "5vw" },
  flex: 1,
};
export const styleTextcardTitle = {
  fontSize: { xs: "3.2vh", sm: "30px", md: "32px", lg: "40px" },
  fontWeight: "bold",
  marginBottom: { xs: "3vh", sm: "3vh", md: "4vh", lg: "5vh" },
  textAlign: "center",
  color: "#000000",
};
export const styleTextcardText = {
  fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "20px", xl: "20px" },
  textAlign: "center",
  color: "#000000",
  paddingInline: { xs: "8vw", sm: "10vw", md: "10vw", lg: "20vw", xl: "20vw" },
};
export const styleBoxImage = {
  display: "flex",
  justifyContent: { xs: "center", sm: "center", md: "flex-start" },
  marginLeft: { xs: "none", sm: "3vw", md: "4vw", lg: "7vw", xl: "10vw" },
  width: "70%",
  marginTop: { xs: "1vh", sm: "3vh", md: "none" },
};

export const styleContenedorItemImage = {
  display: "flex",
  paddingTop: "6vh",
  flexDirection: { xs: "column", sm: "column", md: "row" },
  alignItems: { xs: "center", sm: "center", md: "none" },
  gap: { xs: "4vw", md: "12vw", lg: "10vw", xl: "9vw" },
};
export const styleContenedorItem = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "5vh",
};
export const styleItem = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "3vh",
};
export const styleTitleItem = {
  display: "flex",
  fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "20px", xl: "20px" },
  fontWeight: "bold",
};
export const styleTextItem = {
  display: "flex",
  fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "17px", xl: "18px" },
  paddingTop: "1vh",
};
export const styleItemIcon = {
  height: "2vh",
  display: "flex",
  marginBlock: "3vh",
  width: "100%",
};

export const cedearsStyle = {
  fontSize: "5vh",
  color: "#2a4886",
  transition: "font-size 0.3s, color 0.3s",
  "&:hover": { fontSize: "6vh", color: "#0a265e" },
  textAlign: "center",
};

export const styleContenedorCard = {
  display: "flex",
  flexDirection: "column",
  marginTop: { xs: "5vh", sm: "8vw", md: "8vh", lg: "10vh", xl: "10vh" },
  paddingBottom: { xs: "4vh", sm: "4vw", md: "5vw", lg: "7vw", xl: "7vh" },
  marginInline: { xs: "7vw", sm: "7vw", md: "5vw" },
};
export const styleInvertirTitle = {
  fontSize: { xs: "3.2vh", sm: "30px", md: "32px", lg: "40px" },
  fontWeight: "bold",
  marginBottom: { xs: "3vh", sm: "3vh", md: "4vh", lg: "5vh" },
  textAlign: "center",
  color: "#FFFF",
};
export const styleCardsContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: { xs: "4vw", md: "7vw", lg: "10vw", xl: "17vw" },
  flexWrap: "wrap",
  paddingTop: { xs: "2vh", md: "6vh" },
};
export const styleCardContainer = {
  width: { xs: "100%", md: "17vw", lg: "15vw", xl: "13vw" }, // Ancho responsivo
  backgroundColor: "#FFFF",
  boxShadow: "0 13px 19px rgba(0, 0, 0, 0.07)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingInline: {
    xs: "2vh",
    sm: "3vh",
    md: "4vh",
    lg: "5vh",
    xl: "5vh",
  },
  paddingBlock: "3vh",
  borderRadius: 2,
};
export const styleCardIcon = {
  height: "5vh",
  display: "flex",
  marginBlock: "3vh",
  width: "100%",
};
export const styleCardTitle = {
  fontSize: {
    xs: "2.5vh",
    sm: "22px",
    md: "24px",
    lg: "24px",
    xl: "26px",
  }, // Tamaño de fuente responsivo para título
  fontWeight: "bold",
  textAlign: "center",
};
export const styleCardText = {
  fontSize: {
    xs: "14px",
    sm: "16px",
    md: "16px",
    lg: "16px",
    xl: "18px",
  }, // Tamaño de fuente responsivo para texto
  textAlign: "center",
};

export const styleContenedorAcciones = {
  background: "linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.4));",
  display: "flex",
  flexDirection: "column",
  paddingTop: { xs: "5vh", sm: "5vw", md: "8vw", lg: "5vw", xl: "9vh" },
  paddingBottom: { xs: "5vh", sm: "4vw", md: "3vw", lg: "5vw", xl: "7vh" },
  paddingInline: { xs: "7vw", sm: "7vw", md: "5vw" },
};

export const styleAccionesTextBox = {
  display: "flex",
  flexDirection: { xs: "row", sm: "row", md: "column" }, // Corrige este error
  justifyContent: "space-evenly",
  flexWrap: "wrap",
  height: "100%",
};
export const styleAccionesText = {
  paddingBlock: { xs: "1vh", sm: "3vh", md: "5vh" },
  fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "20px", xl: "22px" },
  paddingTop: { xs: "3vh", sm: "4vh", md: "none" },
};
export const styleContenedorAccionesImage = {
  display: "flex",
  flexDirection: { xs: "column", sm: "column", md: "row" },
  alignItems: { xs: "center", sm: "center", md: "none" },
  gap: { xs: "4vw", sm: "3vw", md: "7vw", lg: "7vw", xl: "13vw" },
};
export const styleAccionsImage = {
  display: "flex",
  justifyContent: { xs: "center", sm: "center", md: "flex-start" },
  marginLeft: { xs: "none", sm: "3vw", md: "4vw", lg: "7vw", xl: "10vw" },
  width: "70%",
};

export const styleContenedorCedears = {
  display: "flex",
  flexDirection: { xs: "column", sm: "column", md: "row" },
  paddingTop: { xs: "5vh", sm: "5vw", md: "8vw", lg: "5vw", xl: "9vh" },
  paddingBottom: "15vh",
  paddingInline: { xs: "7vw", sm: "7vw", md: "5vw" },
  gap: { xs: "4vw", md: "6vw", lg: "9vw", xl: "10vw" },
};
export const styleCedearsTitle = {
  fontSize: { xs: "20px", sm: "20px", md: "30px", lg: "45px", xl: "50px" },
  color: "#FFFF",
};
export const styleCedearsText = {
  textAlign: "justify",
  paddingBlock: { xs: "1vh", sm: "3vh", md: "3vh" },
  fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "18px", xl: "20px" },
  color: "#FFFF",
};

export const styleContenedorCedearsItem = {
  display: "flex",
  flexDirection: { xs: "row", sm: "row", md: "column" },
  justifyContent: "space-evenly",
  flexWrap: "wrap",
  paddingRight: "3vw",
  paddingTop: { xs: "1vh", sm: "2vh", md: "30vh", lg: "30vh", xl: "30vh" },
  gap: { xs: "1vh", sm: "3vh", md: "4vh", lg: "9vh", xl: "9vh" },
};

export const styleContenedorCedearsImage = {
  position: "relative",
  display: { xs: "flex", sm: "flex", md: "inline-block" },
  marginTop: { xs: "3vh", sm: "3vh", md: "5vh", lg: "6vh", xl: "8vh" },
  justifyContent: "center",
};
export const styleCedearsButtonContainer = {
  position: "relative",
  bottom: 0,
  right: { xs: "-4px", sm: "0px" },
};
export const styleCedearsButton = {
  position: "absolute",
  bottom: { xs: "0px", sm: "0px", md: "4px", lg: "4px", xl: "4px" },
  right: { xs: "4px", sm: "0px", md: "0px", lg: "0px", xl: "0px" },
  borderRadius: "15px 0 0 0",
  border: 0,
  height: { xs: "6vh", sm: "5vh", md: "8vh" },
  width: { xs: "28vw", sm: "20vw", md: "20vw", lg: "15vw", xl: "15vw" },
  backgroundColor: "#F5F5F5",
  cursor: "pointer", // Cambia el cursor al pasar el mouse sobre el botón
  color: "#000",
  "&:hover": { backgroundColor: "#D3D3D3" },
};
export const styleCedearButtonText = {
  fontSize: { xs: "12px", sm: "14px", md: "18px", lg: "20px", xl: "20px" },
  textTransform: "none",
};
export const styleCedearButtonIcon = {
  display: "flex",
  height: { xs: "15px", sm: "20px", md: "25px", lg: "25px", xl: "30px" },
};
