import React, { useState } from "react";
import { Box, Tooltip, Snackbar, Alert, IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import * as functions from "../../functions/calculoCartera";

const Copiado = ({ acciones }) => {
  const [copiado, setCopiado] = useState(false);

  const handleCopy = () => {
    const codigo = functions.generarCodigoIdentificador(acciones);
    navigator.clipboard.writeText(codigo).then(() => {
      setCopiado(true);
      setTimeout(() => setCopiado(false), 1500); // Restablecer el estado de 'copiado' después de 1.5 segundos
    });
  };

  return (
    <Box>
      <Tooltip
        title={"Copia el código podrás recrear tu Portafolio luego"}
        placement="top"
        arrow
      >
        <IconButton onClick={() => handleCopy()} style={{ color: "#2A4887" }}>
          <FileCopyIcon sx={{ cursor: "pointer", marginLeft: "5px" }} />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={copiado}
        autoHideDuration={1500}
        onClose={() => setCopiado(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setCopiado(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Copiado al portapapeles
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Copiado;
