import * as React from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { PreciosContext } from "../hooks/PricesContext";
import { Box } from "@mui/material";
import Morefinancial from "../components/cedears/MoreFinancial.js";
import * as styles from "../styles/Screener.js";
import FiltersTable from "../components/screener/Filter.js";
import TableScreener from "../components/screener/Table.js";

export default function EnhancedTable() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRatios, setSelectedRatios] = useState({
    PER: true,
    EVtoFCF: true,
    PtoSales: true,
    Precio: true,
    Variacion: true,
    MgNet: true,
    Var1Y: true,
    Var5Y: true,
  });

  const [showMoreFinancial, setShowMoreFinancial] = useState(true);
  const [selectedTicker, setSelectedTicker] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const [companyFilter, setCompanyFilter] = React.useState("");
  const [ratioFilters, setRatioFilters] = React.useState({
    PER: { min: "", max: "" },
    EVtoFCF: { min: "", max: "" },
    PtoSales: { min: "", max: "" },
    Precio: { min: "", max: "" },
    Variacion: { min: "", max: "" },
    MgNet: { min: "", max: "" },
    Var1Y: { min: "", max: "" },
    Var5Y: { min: "", max: "" },
  });

  const { stocks } = useContext(PreciosContext);

  const calcularVariacion = (selectedValue, data) => {
    if (data.length > selectedValue - 1) {
      const dataForDay = data[data.length - selectedValue - 1]; //Calcula el dato del primer dia del periodo seleccionado
      const precioDay = dataForDay.price; //Se queda con el precio del objeto
      const dataHoy = data[data.length - 1]; //Calcula el precio de hoy
      const precioHoy = dataHoy.price; //Se queda con el precio del objeto
      const variacion = ((precioHoy - precioDay) / precioDay) * 100;
      return variacion.toFixed(2);
    } else {
      //Si tiene menos de 5 años, toma el primer dia de datos como el primer dia del periodo
      const dataForDay = data[0];
      const precioDay = dataForDay.price;
      const dataHoy = data[data.length - 1];
      const precioHoy = dataHoy.price;
      const variacion = ((precioHoy - precioDay) / precioDay) * 100;
      return variacion.toFixed(2);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const formattedData = stocks.map((stock) => {
      const fullStock = stock;
      const PER = ((stock.price * stock.Acciones) / stock.NetIncome).toFixed(2);
      let EVtoFCF;
      if (stock.category === "Financiero") {
        // Calcular EV/FCF igual que P/FCF para categoría Financiero
        EVtoFCF = PER;
      } else {
        EVtoFCF = (
          (stock.price * stock.Acciones + stock.Debt - stock.CashandEquiv) /
          (stock.NetIncome + stock.DandA - stock.CAPEX)
        ).toFixed(2);
      }
      const PtoSales = ((stock.price * stock.Acciones) / stock.Revenue).toFixed(
        2
      );
      const Precio = parseFloat(stock.price);
      const Variacion = parseFloat(
        (stock.actual_price - stock.price) / stock.price
      );
      const data = stock.data;
      // const variacion1W = calcularVariacion (5, data) // Variacion 1 semana
      const Var1Y = calcularVariacion(250, data); // Variacion 1 año
      const Var5Y = calcularVariacion(1200, data); // Variacion 5 años

      const MgNet = stock.NetIncome / stock.Revenue;

      return {
        name: stock.name,
        PER: PER,
        EVtoFCF: EVtoFCF,
        PtoSales: PtoSales,
        Precio: Precio,
        Variacion: Variacion,
        MgNet: MgNet,
        fullStock: fullStock,
        image: stock.image,
        Var1Y: Var1Y,
        Var5Y: Var5Y,
      };
    });

    if (formattedData.length > 0) {
      setIsLoading(false);
    }
    setRows(formattedData);
  }, []);

  const handleRatioSelectionChange = (event, ratio) => {
    console.log(ratio, event);
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      if (
        (ratio === "PER" || ratio === "EVtoFCF" || ratio === "PtoSales") &&
        name === "max"
      ) {
        // Para PER, EVtoFCF y PtoSales, solo permitir valores en el campo "max"
        setRatioFilters((prevRatioFilters) => ({
          ...prevRatioFilters,
          [ratio]: {
            ...prevRatioFilters[ratio],
            [name]: value,
          },
        }));
      } else if (ratio === "MgNet" && name === "min") {
        // Para MgNet, solo permitir valores en el campo "min"
        setRatioFilters((prevRatioFilters) => ({
          ...prevRatioFilters,
          [ratio]: {
            ...prevRatioFilters[ratio],
            [name]: value,
          },
        }));
      }
    } else {
      setRatioFilters((prevRatioFilters) => ({
        ...prevRatioFilters,
        [ratio]: {
          min: "",
          max: "",
        },
      }));
    }
  };

  const handleRemoveFilter = (ratio) => {
    setRatioFilters((prevRatioFilters) => ({
      ...prevRatioFilters,
      [ratio]: {
        min: "",
        max: "",
      },
    }));
  };

  const handleCompanyClick = (fullStock, image) => {
    setSelectedTicker(fullStock);
    setSelectedImage(image);
    setShowMoreFinancial(false);
    console.log(fullStock);
  };

  return (
    <Box style={styles.styleContainer}>
      {!showMoreFinancial && (
        <Morefinancial
          stock={selectedTicker}
          setFront={setShowMoreFinancial}
          image={selectedImage}
          fixedPosition={true}
        />
      )}
      {showMoreFinancial && (
        <FiltersTable
          companyFilter={companyFilter}
          setCompanyFilter={setCompanyFilter}
          selectedRatios={selectedRatios}
          ratioFilters={ratioFilters}
          handleRatioSelectionChange={handleRatioSelectionChange}
          handleRemoveFilter={handleRemoveFilter}
        ></FiltersTable>
      )}
      {/* Esta es la box del selector de ratios, por ahora desactivado */}
      <Box
        sx={{
          display: "none",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Box>
          {Object.keys(selectedRatios).map((ratio) => (
            <label key={ratio}>
              <input
                type="checkbox"
                checked={selectedRatios[ratio]}
                onChange={() => handleRatioSelectionChange(ratio)}
              />
              {ratio}
            </label>
          ))}
        </Box>
      </Box>
      {showMoreFinancial && (
        <TableScreener
          rows={rows}
          companyFilter={companyFilter}
          selectedRatios={selectedRatios}
          styles={styles}
          ratioFilters={ratioFilters}
          handleCompanyClick={handleCompanyClick}
          isLoading={isLoading}
        ></TableScreener>
      )}
    </Box>
  );
}
