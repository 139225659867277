import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

//Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9OOVhxB_3W_4TrfHWC1za2go__DM9fug",
  authDomain: "inversimple-project.firebaseapp.com",
  projectId: "inversimple-project",
  storageBucket: "inversimple-project.appspot.com",
  messagingSenderId: "308773829610",
  appId: "1:308773829610:web:08c7a69ac01ae121e2d093",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };
