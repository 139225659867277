import React from "react";
import { Box, Typography } from "@mui/material";
import * as title from "../../constants/titles_home.js";
import * as styles from "../../styles/Home.js";
import Card from "./Card.js";

const Invertir = () => {
  return (
    <Box sx={styles.styleContenedorCard}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={styles.styleInvertirTitle}>
          ¿Por qué Invertir?
        </Typography>
      </Box>
      <Box sx={styles.styleCardsContainer}>
        {title.porqueInvertir.map((data, index) => (
          <Card
            key={index}
            title={data.title}
            text={data.text}
            icon={data.image}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Invertir;
