import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Line,
} from "recharts";

const BPAChart = ({ stock }) => {
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.35); // Inicializar el ancho del gráfico al 20% del ancho de la ventana
  const [chartHeight, setChartHeight] = useState(window.innerHeight); // Inicializar el alto del gráfico al 20% del alto de la ventana

  const theme = useTheme();

  const calculateNewWidth = () => {
    const { xs, sm, md, lg, xl } = theme.breakpoints.values;

    if (window.innerWidth <= sm) {
      return [window.innerWidth * 0.85, window.innerHeight * 0.32];
    } else if (window.innerWidth <= md) {
      return [window.innerWidth * 0.85, window.innerHeight * 0.32]; // ajusta el valor para sm
    } else if (window.innerWidth < lg) {
      return [window.innerWidth * 0.7, window.innerHeight * 0.32]; // ajusta el valor para md// ajusta el valor para lg
    } else if (window.innerWidth <= xl) {
      return [window.innerWidth * 0.28, window.innerHeight * 0.4]; // ajusta el valor para lg
    } else {
      return [window.innerWidth * 0.35, window.innerHeight * 0.4]; // ajusta el valor para xl
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Verificar el ancho de la ventana

      let [newWidth, newHeight] = calculateNewWidth();

      // Actualizar el tamaño del gráfico
      setChartWidth(newWidth);
      setChartHeight(newHeight);
    };

    // Agregar el listener para el evento resize
    window.addEventListener("resize", handleResize);

    // Llamar a la función de manejo de resize para establecer las dimensiones iniciales
    handleResize();

    // Limpiar el listener del evento resize al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = [
    {
      name: "2018",
      Acciones: stock.Acciones2018,
      BPA: stock.NetIncome2018 / stock.Acciones2018,
    },
    {
      name: "2019",
      Acciones: stock.Acciones2019,
      BPA: stock.NetIncome2019 / stock.Acciones2019,
    },
    {
      name: "2020",
      Acciones: stock.Acciones2020,
      BPA: stock.NetIncome2020 / stock.Acciones2020,
    },
    {
      name: "2021",
      Acciones: stock.Acciones2021,
      BPA: stock.NetIncome2021 / stock.Acciones2021,
    },
    {
      name: "2022",
      Acciones: stock.Acciones2022,
      BPA: stock.NetIncome2022 / stock.Acciones2022,
    },
    {
      name: "2023",
      Acciones: stock.Acciones2023,
      BPA: stock.NetIncome2023 / stock.Acciones2023,
    },
  ];

  const accionesValues = data.map((entry) => entry.Acciones);
  const accionesMax = Math.max(...accionesValues);
  const roundedMaxAcciones = Math.ceil((accionesMax * 1.5) / 50) * 50; // Sumar un 50% y luego redondear hacia arriba al múltiplo de 50 más cercano

  const bpaValues = data.map((entry) => entry.BPA);
  const maxBPA = Math.max(...bpaValues);
  const roundedMaxBPA = Math.ceil((maxBPA * 1.2) / 4) * 4; // Redondear hacia arriba al múltiplo de 5 más cercano

  const formatBPA = (value) => value.toFixed(2);
  const formatAcciones = (value) =>
    `${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}M`; // Dividir por un millón, añadir punto como separador de miles y añadir "M" al final

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        sx={{
          fontSize: { xs: "16px", md: "18px" },
          fontWeight: "bold",
          marginBottom: "10px",
          display: { xs: "none", md: "block" },
        }}
      >
        Beneficio por Accion - Acciones en Circulacion
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          marginBottom: "10px",
          display: { xs: "block", md: "none" },
        }}
      >
        Beneficio por Accion - Acciones
      </Typography>
      <ComposedChart width={chartWidth} height={chartHeight} data={data}>
        <XAxis dataKey="name" tick={{ fontFamily: "Arial", fontSize: 13 }} />
        <YAxis
          yAxisId="acciones"
          orientation="left"
          domain={[0, roundedMaxAcciones]}
          tickFormatter={formatAcciones}
          tick={{ fontFamily: "Arial", fontSize: 13 }}
        />
        <YAxis
          yAxisId="bpa"
          orientation="right"
          domain={[0, roundedMaxBPA]}
          tickFormatter={formatBPA}
          tick={{ fontFamily: "Arial", fontSize: 13 }}
        />
        <Tooltip
          formatter={(value, name) => [
            name === "BPA" ? formatBPA(value) : formatAcciones(value),
            name,
          ]}
        />
        <Legend />
        <CartesianGrid stroke="#f5f5f5" />
        <Bar dataKey="BPA" barSize={20} fill="#8884d8" yAxisId="bpa" />
        <Line
          type="monotone"
          dataKey="Acciones"
          stroke="#ff7300"
          yAxisId="acciones"
        />
      </ComposedChart>
    </Box>
  );
};

export default BPAChart;
