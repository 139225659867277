const calculoPerAccion = (ticker, stocks) => {
  const stock = stocks.find((stock) => stock.ticker === ticker);
  return (stock.actual_price * stock.Acciones) / stock.NetIncome;
};

export const calcularValorTotal = (a, acciones, stocks, dolarCCL) => {
  console.log(dolarCCL);
  let valorTotalUSD = 0;
  let valorTotalARS = 0;
  acciones.forEach((accion) => {
    const stock = stocks.find((stock) => stock.ticker === accion.ticker);
    if (stock) {
      valorTotalUSD += (accion.cantidad * stock.actual_price) / stock.ratio;
      valorTotalARS +=
        ((accion.cantidad * stock.actual_price) / stock.ratio) * dolarCCL;
    }
  });
  if (a === "USD") {
    return valorTotalUSD;
  } else if (a === "ARS") {
    return valorTotalARS;
  }
};

export const calculoPerCartera = (acciones, stocks, dolarCCL) => {
  let perPonderado = 0;
  acciones.forEach((accion) => {
    const stock = stocks.find((stock) => stock.ticker === accion.ticker);
    if (stock) {
      const per = calculoPerAccion(stock.ticker, stocks);
      perPonderado +=
        (per *
          calcularPorcentaje(
            accion.cantidad,
            accion.ticker,
            calcularValorTotal("ARS", acciones, stocks, dolarCCL),
            stocks,
            dolarCCL
          )) /
        100;
    }
  });
  return perPonderado;
};

export const valorAccion = (ticker, cantidad, stocks, dolarCCL) => {
  const stock = stocks.find((stock) => stock.ticker === ticker);
  const precioARSRatio = (stock.actual_price / stock.ratio) * dolarCCL;
  const valorAccion = cantidad * precioARSRatio;
  return valorAccion;
};

export const valorAccionUSD = (ticker, cantidad, stocks) => {
  const stock = stocks.find((stock) => stock.ticker === ticker);
  const precioARSRatio = stock.actual_price / stock.ratio;
  const valorAccion = (cantidad * precioARSRatio).toLocaleString("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return valorAccion;
};

export const calcularPorcentaje = (
  cantidad,
  ticker,
  valorTotal,
  stocks,
  dolarCCL
) => {
  const valorAcciones = valorAccion(ticker, cantidad, stocks, dolarCCL);
  return ((valorAcciones / valorTotal) * 100).toFixed(2);
};

const calculoEVtoFCFAccion = (ticker, stocks) => {
  const stock = stocks.find((stock) => stock.ticker === ticker);
  if (stock.category === "Financiero") {
    return (stock.actual_price * stock.Acciones) / stock.NetIncome;
  } else {
    const FCF = stock.NetIncome + stock.DandA - stock.CAPEX;
    if (FCF < 0) {
      // Manejar FCF negativo (por ejemplo, asignar un valor predeterminado)
      return 100; // O cualquier otro valor predeterminado que consideres adecuado
    } else {
      return parseFloat(
        (stock.actual_price * stock.Acciones +
          stock.Debt -
          stock.CashandEquiv) /
          FCF
      );
    }
  }
};

export const calculoEVtoFCFCartera = (acciones, stocks, dolarCCL) => {
  let fcfPonderado = 0;
  acciones.forEach((accion) => {
    const stock = stocks.find((stock) => stock.ticker === accion.ticker);
    if (stock) {
      const fcf = calculoEVtoFCFAccion(stock.ticker, stocks);
      fcfPonderado +=
        (fcf *
          calcularPorcentaje(
            accion.cantidad,
            accion.ticker,
            calcularValorTotal("ARS", acciones, stocks, dolarCCL),
            stocks,
            dolarCCL
          )) /
        100;
    }
  });

  return fcfPonderado;
};

export const generarCodigoIdentificador = (acciones) => {
  let codigo = "";
  acciones.forEach((accion, index) => {
    codigo += `${accion.cantidad}${accion.ticker}`;
  });
  return codigo;
};

export const generarPortfolio = ({ codigoPortfolio }) => {
  const regex = /(\d+)([A-Za-z.]+)/g; // Modificamos la expresión regular para incluir el punto como un carácter válido en el ticker
  const nuevasAcciones = [];

  let match;
  while ((match = regex.exec(codigoPortfolio)) !== null) {
    const cantidad = parseInt(match[1], 10);
    const ticker = match[2];
    nuevasAcciones.push({ ticker, cantidad });
  }

  return nuevasAcciones; // Devuelve las acciones generadas
};

export const calcularVariacion = (accion, stocks) => {
  const stock = stocks.find((stock) => stock.ticker === accion.ticker);

  return parseFloat(
    ((stock.actual_price - stock.price) / stock.price) * 100
  ).toFixed(2);
  // Calcular el rendimiento de la acción
};

export const calcularVariacionPortfolio = (acciones, stocks, dolarCCL) => {
  let rendimientosPonderados = 0;
  let valorTotalPortfolioInicial = 0;

  // Calcular el valor total del portfolio inicial
  valorTotalPortfolioInicial = calcularValorTotal(
    "ARS",
    acciones,
    stocks,
    dolarCCL
  );

  // Calcular el rendimiento ponderado de cada acción y sumarlos
  acciones.forEach((accion) => {
    const stock = stocks.find((stock) => stock.ticker === accion.ticker);
    if (stock) {
      const rendimientoAccion =
        ((stock.actual_price - stock.price) / stock.price) * 100; // Calcular el rendimiento de la acción

      const ponderacionAccion =
        calcularPorcentaje(
          accion.cantidad,
          accion.ticker,
          valorTotalPortfolioInicial,
          stocks,
          dolarCCL
        ) / 100; // Calcular la ponderación de la acción
      rendimientosPonderados += rendimientoAccion * ponderacionAccion; // Sumar el rendimiento ponderado de la acción al total
    }
  });

  return rendimientosPonderados.toFixed(2); // Devolver el rendimiento ponderado total del portfolio
};

export const calcularVariacionPortfolioUSD = (variacion, valueUSD) => {
  return (variacion * valueUSD) / 100; // Devolver el rendimiento ponderado total del portfolio
};

export const calcularPrecioCedear = (accion, stocks) => {
  const stock = stocks.find((stock) => stock.ticker === accion.ticker);

  return parseFloat(stock.actual_price / stock.ratio).toFixed(2);
  // Calcular el rendimiento de la acción
};
