export const calculoPer = (stock) => {
  return ((stock.price * stock.Acciones) / stock.NetIncome).toFixed(2);
};

export const calculoEvEbitda = (stock) => {
  return (
    (stock.price * stock.Acciones + stock.Debt - stock.CashandEquiv) /
    (stock.OpIncome + stock.DandA)
  ).toFixed(2);
};

export const calculoEvFcf = (stock) => {
  return (
    (stock.price * stock.Acciones + stock.Debt - stock.CashandEquiv) /
    (stock.NetIncome + stock.DandA - stock.CAPEX)
  ).toFixed(2);
};

export const calculoPSales = (stock) => {
  return ((stock.price * stock.Acciones) / stock.Revenue).toFixed(2);
};

export const calculoVariacion = (stock) => {
  return ((stock.actual_price - stock.price) / stock.price) * 100;
};
