import React, { useState } from "react";
import VentasChart from "../chart/VentasChart.js";
import BPAChart from "../chart/BPAChart.js";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Stepper from "../Stepper.js";

const ChartContainer = ({ stock }) => {
  const [showFirstChart, setShowFirstChart] = useState(true); // Estado para controlar qué gráfico se muestra

  const toggleChart = () => {
    setShowFirstChart(!showFirstChart); // Cambia el estado para alternar entre los gráficos
  };

  return (
    <Box>
      <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <Button onClick={toggleChart} style={{ color: "#2A4887" }}>
          <FontAwesomeIcon icon={faArrowLeft} size="2x" />
        </Button>
        {/* Renderiza el primer gráfico si showFirstChart es true, de lo contrario, renderiza el segundo */}
        {showFirstChart ? (
          <VentasChart stock={stock} />
        ) : (
          <BPAChart stock={stock} />
        )}
        <Button onClick={toggleChart} style={{ color: "#2A4887" }}>
          <FontAwesomeIcon icon={faArrowRight} size="2x" />
        </Button>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Stepper
          Step1Component={VentasChart}
          Step2Component={BPAChart}
          stock={stock}
        ></Stepper>
      </Box>
    </Box>
  );
};

export default ChartContainer;
