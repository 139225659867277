import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../styles/Home.js";

const Item = ({ icon, title, text, colorBox, colorText }) => {
  return (
    <Box sx={styles.styleContenedorItem}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            width: "51px",
            height: "51px",
            backgroundColor: colorBox,
            borderRadius: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={icon} alt={title} sx={styles.styleItemIcon} />
        </Box>
        <Box sx={styles.styleItem}>
          <Typography sx={{ ...styles.styleTitleItem, color: colorText }}>
            {title}
          </Typography>
          <Typography sx={{ ...styles.styleTextItem, color: colorText }}>
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Item;
