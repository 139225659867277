import React from "react";
import { Box } from "@mui/material";

const colorMap = {
  green: {
    color: "#549a6b",
    backgroundColor: "#e5f4ea",
    borderColor: "#b7eb8f",
  },
  red: {
    color: "#a50e13",
    backgroundColor: "#fce8e6",
    borderColor: "#ffa39e",
  },
  orange: {
    // Añadiendo una entrada para el naranja
    color: "#d48806",
    backgroundColor: "#fffbe6",
    borderColor: "#ffe58f",
  },
  default: {
    color: "#000000",
    backgroundColor: "#ffffff",
    borderColor: "#cccccc",
  },
};

const Tag = ({ text, colorChoice, sx, children }) => {
  const { color, backgroundColor } = colorMap[colorChoice] || colorMap.default;
  const tagStyle = {
    display: "inline-block",
    padding: "4px 8px",
    borderRadius: "6px",
    fontSize: { xs: "13px", md: "16px", lg: "18px", xl: "20px" },
    color,
    backgroundColor,
  };

  return (
    <Box sx={{ ...sx, ...tagStyle }}>
      {children}
      {text}
    </Box>
  );
};

export default Tag;
