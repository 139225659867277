import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../../styles/Home.js";

const CardInvertir = ({ title, text, icon }) => {
  return (
    <Box sx={styles.styleCardContainer}>
      <div>
        <Typography sx={styles.styleCardTitle}>{title}</Typography>
        <img src={icon} alt={title} style={styles.styleCardIcon} />
        <Typography sx={styles.styleCardText}>{text}</Typography>
      </div>
    </Box>
  );
};

export default CardInvertir;
