import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState, useMemo } from "react";
import * as tableHead from "../../constants/table.js";
import EnhancedTableHead from "./TableHead.js";

export default function TableScreener({
  rows,
  companyFilter,
  selectedRatios,
  styles,
  ratioFilters,
  handleCompanyClick,
  isLoading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("PER");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "Variacion" || orderBy === "Var1Y" || orderBy === "Var5Y") {
      // Comparar los valores de variación directamente
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      // Si los valores son diferentes, retornar el resultado de la comparación
      if (aValue !== bValue) {
        return bValue - aValue; // Ordenar de mayor a menor
      }

      // Si los valores son iguales, retornar 0
      return 0;
    }

    // Convertir los valores a números antes de comparar
    const aValue =
      typeof a[orderBy] === "string" ? parseFloat(a[orderBy]) : a[orderBy];
    const bValue =
      typeof b[orderBy] === "string" ? parseFloat(b[orderBy]) : b[orderBy];

    // Manejar la comparación normal para todas las columnas excepto "Variacion"
    if (orderBy !== "Variacion" || orderBy !== "Var1Y") {
      if (bValue < 0 && aValue >= 0) {
        return 1;
      }
      if (bValue >= 0 && aValue < 0) {
        return -1;
      }
    }

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy))
      .filter((row) =>
        row.name.toLowerCase().includes(companyFilter.toLowerCase())
      )
      .filter((row) => {
        for (const [key, value] of Object.entries(ratioFilters)) {
          const floatValue = parseFloat(row[key]);
          if (value.max !== "" && floatValue < 0) {
            // Si se busca un valor máximo y el valor es negativo, excluimos la fila
            return false;
          }
          if (value.max !== "" && floatValue > parseFloat(value.max)) {
            // Si se establece un valor máximo y el valor de la fila es mayor,
            // filtramos
            return false;
          }
          if (value.min !== "" && floatValue < parseFloat(value.min)) {
            // Si se establece un valor mínimo y el valor de la fila es menor,
            // filtramos
            return false;
          }
        }
        return true;
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rows, order, orderBy, page, rowsPerPage, companyFilter, ratioFilters]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      }}
    >
      <TableContainer
        sx={{
          borderRadius: "15px",
          maxHeight: "75vh",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        }}
      >
        <Table
          stickyHeader
          size="medium"
          sx={{
            minWidth: 750,
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            selectedRatios={selectedRatios}
          />
          <TableBody style={styles.styleContainerTable}>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={10}>Cargando...</TableCell>
              </TableRow>
            ) : (
              visibleRows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={1}
                    key={row.name}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleCompanyClick(row.fullStock, row.image)}
                  >
                    {tableHead.headCells.map((headCell) => (
                      <TableCell
                        align={headCell.id === "name" ? "left" : "center"}
                        sx={{
                          paddingLeft: headCell.id === "name" ? "30px" : "0px",
                        }}
                        key={headCell.id}
                      >
                        {headCell.id === "name" ? (
                          row.name
                        ) : headCell.id === "Precio" ? (
                          `$ ${row[headCell.id].toFixed(2)}`
                        ) : headCell.id === "Variacion" ? (
                          <span
                            style={{
                              color: row.Variacion >= 0 ? "green" : "red",
                            }}
                          >
                            {(row[headCell.id] * 100).toLocaleString(
                              undefined,
                              { maximumFractionDigits: 2 }
                            )}
                            %
                          </span>
                        ) : headCell.id === "MgNet" ? (
                          `${(row[headCell.id] * 100).toLocaleString(
                            undefined,
                            { maximumFractionDigits: 2 }
                          )}%`
                        ) : headCell.id === "Var1Y" ? (
                          <span
                            style={{
                              color: row.Var1Y >= 0 ? "green" : "red",
                            }}
                          >
                            {row[headCell.id].toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>
                        ) : headCell.id === "Var5Y" ? (
                          <span
                            style={{
                              color: row.Var5Y >= 0 ? "green" : "red",
                            }}
                          >
                            {row[headCell.id].toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>
                        ) : (
                          row[headCell.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
