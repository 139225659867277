export const container = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "row",
    lg: "row",
    xl: "row",
  },
  alignItems: "center",
  gap: {
    xs: 2,
    sm: 2,
    md: 0,
  },
  paddingBottom: 3,
  justifyContent: "center",
};

export const text = {
  color: "#000",
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "13px",
    lg: "13px",
    xl: "13px",
  },
};

export const textField = {
  marginRight: "10px",

  "& input": {
    height: "10px",
    color: "#2A4887",
  },
  "& fieldset": {
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#2A4887", // Cambia el color del borde del TextField
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2A4887", // Cambia el color del borde cuando está seleccionado
    },
  },
};

export const textFieldContainer = {
  display: "flex",
  alignItems: "center",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "row",
    lg: "row",
    xl: "row",
  },
  gap: {
    xs: 2,
    sm: 2,
    md: 0,
  },
  justifyContent: "center",
};
