import {
  Box,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XAxis, YAxis, Tooltip, AreaChart, Area } from "recharts";
import * as styles from "../../styles/Form";
import * as chart from "../../styles/MoreFinancial.js";
import * as functions from "../../functions/calculoRatios.js";
import UpIcon from "../../utils/images/arrow-thick-top.svg";
import DownIcon from "../../utils/images/arrow-thick-bottom.svg";

const PricesChart = ({ stock }) => {
  const data = stock.data;
  const formatPrice = (value) => parseFloat(value).toFixed(2);
  const [dataToShow, setDataToShow] = useState([]);
  const [minY, setMinY] = useState(0);
  const [maxY, setMaxY] = useState(0);
  const [selectedValue, setSelectedValue] = useState(250);
  const [chartWidth, setChartWidth] = useState(window.innerWidth); // Inicializar el ancho del gráfico al 20% del ancho de la ventana
  const [chartHeight, setChartHeight] = useState(window.innerHeight); // Inicializar el alto del gráfico al 20% del alto de la ventana
  const variacion = functions.calculoVariacion(stock);

  const theme = useTheme();

  const calculateNewWidth = () => {
    const { xs, sm, md, lg, xl } = theme.breakpoints.values;

    if (window.innerWidth <= sm) {
      return [window.innerWidth * 0.75, window.innerHeight * 0.32];
    } else if (window.innerWidth <= md) {
      return [window.innerWidth * 0.9, window.innerHeight * 0.32]; // ajusta el valor para sm
    } else if (window.innerWidth < lg) {
      return [window.innerWidth * 0.85, window.innerHeight * 0.32]; // ajusta el valor para md// ajusta el valor para lg
    } else if (window.innerWidth <= xl) {
      return [window.innerWidth * 0.43, window.innerHeight * 0.32]; // ajusta el valor para lg
    } else {
      return [window.innerWidth * 0.4, window.innerHeight * 0.32]; // ajusta el valor para xl
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Verificar el ancho de la ventana

      let [newWidth, newHeight] = calculateNewWidth();

      // Actualizar el tamaño del gráfico
      setChartWidth(newWidth);
      setChartHeight(newHeight);
    };

    // Agregar el listener para el evento resize
    window.addEventListener("resize", handleResize);

    // Llamar a la función de manejo de resize para establecer las dimensiones iniciales
    handleResize();

    // Limpiar el listener del evento resize al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Función para actualizar la cantidad de datos que se muestran
  const updateDataToShow = (count) => {
    setDataToShow(data.slice(-count));
  };

  // Función para calcular el valor máximo y mínimo del eje Y
  const calculateMinMaxY = () => {
    const prices = dataToShow.map((entry) => parseFloat(entry.price));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const minYValue = Math.floor(minPrice / 10) * 10;
    const maxYValue = Math.ceil(maxPrice / 10) * 10;

    setMinY(minYValue);
    setMaxY(maxYValue);
  };

  // Función para formatear las fechas y mostrar solo los meses
  const formatXAxis = (tickItem) => {
    //console.log(tickItem)
    const dateParts = tickItem.split("-");
    //console.log(dateParts)
    console.log(dataToShow.length);
    const year = dateParts[2].substring(2); // Obtiene los dos últimos dígitos del año
    const month = dateParts[1].substring(0, 2);
    const day = dateParts[0].substring(8); // Obtiene los dos últimos dígitos del día
    if (dataToShow.length > 1000) {
      return `${dateParts[1]}/${year}`;
    } else if (dataToShow.length > 600) {
      return `${dateParts[1]}/${year}`;
    } else if (dataToShow.length > 200) {
      return `${dateParts[1]}/${year}`;
    } else if (dataToShow.length > 100) {
      return `${dateParts[0]}/${month}`;
    } else {
      return `${dateParts[0]}/${month}`;
    }
  };

  const getInterval = (length) => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      if (length <= 100) {
        return 2; // Mostrar cada 2da etiqueta
      } else if (length <= 200) {
        return 20; // Mostrar cada 4ta etiqueta
      } else if (length <= 1000) {
        return 60; // Mostrar cada 6ta etiqueta
      } else {
        return 210; // Mostrar cada 10ma etiqueta
      }
    } else {
      if (length <= 100) {
        return 1; // Mostrar cada etiqueta
      } else if (length <= 200) {
        return 10; // Mostrar cada 2da etiqueta
      } else if (length <= 1000) {
        return 30; // Mostrar cada 4ta etiqueta
      } else {
        return 105; // Mostrar cada 6ta etiqueta
      }
    }
  };

  const calcularVariacion = (selectedValue) => {
    if (data.length > selectedValue -1){
      const dataForDay = data[data.length - selectedValue - 1]; //Calcula el dato del primer dia del periodo seleccionado
      const precioDay = dataForDay.price; //Se queda con el precio del objeto
      const dataHoy = data[data.length - 1]; //Calcula el precio de hoy
      const precioHoy = dataHoy.price; //Se queda con el precio del objeto
      const variacion = (precioHoy - precioDay)/precioDay*100
      return (variacion).toFixed(2);
    }
    else{
      const dataForDay = data[0]; //Calcula el dato del primer dia del periodo seleccionado
      const precioDay = dataForDay.price; //Se queda con el precio del objeto
      const dataHoy = data[data.length - 1]; //Calcula el precio de hoy
      const precioHoy = dataHoy.price; //Se queda con el precio del objeto
      const variacion = (precioHoy - precioDay)/precioDay*100
      return (variacion).toFixed(2);
    }
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const value = event.target.value;
    updateDataToShow(value);
  };

  useEffect(() => {
    updateDataToShow(250); // Establecer por defecto "1 Año" al cargar el componente
  }, []);

  // Actualizar el valor máximo y mínimo del eje Y cuando cambia la cantidad de datos
  useEffect(() => {
    calculateMinMaxY();
  }, [dataToShow]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingBlock: "1em",
      }}
    >
      <Box sx={{ paddingRight: "1em" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Alinea verticalmente los elementos
            justifyContent: "space-between", // Espacio entre los elementos
            paddingLeft: "4vw",
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: { xs: "90%", sm: "100%" },
              gap: 2,
            }}
          >
            <Typography sx={chart.priceText}>
              Precio:{`$${stock.actual_price}`}
            </Typography>
            <Typography sx={chart.variacionText}>
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
              >
                Variación:{" "}
              </Box>
              {variacion > 0 && (
                <img
                  src={UpIcon}
                  alt="Flecha hacia arriba"
                  style={{
                    width: "1em",
                    height: "0.8em",
                    fill: "green",
                  }}
                />
              )}
              {variacion < 0 && (
                <img
                  src={DownIcon}
                  alt="Flecha hacia abajo"
                  style={{
                    width: "1em",
                    height: "0.8em",
                  }}
                />
              )}
              <span
                style={{
                  color: variacion < 0 ? "red" : "#1a990f",
                }}
              >
                {variacion.toFixed(2)}%
              </span>
            </Typography>
          </Box>
          <Box sx={{display:"flex", }}>
            {calcularVariacion(selectedValue) > 0 && (
                  <img
                    src={UpIcon}
                    alt="Flecha hacia arriba"
                    style={{
                      width: "1em",
                      height: "0.8em",
                      fill: "green",
                    }}
                  />
                )}
                {calcularVariacion(selectedValue) < 0 && (
                  <img
                    src={DownIcon}
                    alt="Flecha hacia abajo"
                    style={{
                      width: "1em",
                      height: "0.8em",
                    }}
                  />
                )}
                <span
                style={{
                  color: calcularVariacion(selectedValue) < 0 ? "red" : "#1a990f",
                }}
              >
                {calcularVariacion(selectedValue)}%
              </span>
          </Box>
          <FormControl fullWidth sx={{ alignItems: "flex-end" }}>
            <Select
              id="year"
              name="year"
              value={selectedValue}
              onChange={handleChange}
              sx={{ ...styles.selectStyle, width: "fit-content", boxShadow: 2 }}
            >
              <MenuItem value={1200}>5 Años</MenuItem>
              <MenuItem value={250}>1 Año</MenuItem>
              <MenuItem value={125}>6 Meses</MenuItem>
              <MenuItem value={20}>1 Mes</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Gráfico */}
      <AreaChart width={chartWidth} height={chartHeight} data={dataToShow}>
        <XAxis
          dataKey="date"
          tickFormatter={(value, index) =>
            index !== 0 ? formatXAxis(value) : ""
          }
          interval={getInterval(dataToShow.length)}
          padding={{ right: 15 }}
          tick={{ fontFamily: "Arial", fontSize: 13 }}
        />
        <YAxis
          domain={[minY, maxY]}
          padding={{ top: 20 }}
          tick={{ fontFamily: "Arial", fontSize: 13 }}
        />
        <Tooltip
          formatter={(value, name, props) => [
            `$${formatPrice(value)}`,
            props.label,
          ]}
          labelFormatter={(label) => `${label}: `}
        />
        <Area
          type="monotone"
          dataKey="price"
          stroke="#8884d8"
          strokeWidth={2}
          fill="#8884d8"
          fillOpacity={0.1}
          dot={false}
        />
      </AreaChart>
    </Box>
  );
};

export default PricesChart;
