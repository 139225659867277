export const container = (color) => ({
  backgroundColor: color,
  boxShadow: "0 13px 19px rgba(0, 0, 0, 0.07)",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingLeft: {
    xs: "2vh",
    sm: "3vh",
    md: "4vh",
    lg: "4vh",
    xl: "5vh",
  },
  paddingTop: "3vh",
  paddingBottom: "3vh",
  borderRadius: 2,
  width: "100%",
  height: {
    xs: "220px",
    sm: "270px",
    md: "280px",
    lg: "300px",
    xl: "300px",
  },
});
export const containerTitle = (colorText) => ({
  fontSize: {
    xs: "16px",
    sm: "19px",
    md: "18px",
    lg: "22px",
    xl: "26px",
  }, // Tamaño de fuente responsivo para título
  fontWeight: "bold",
  paddingTop: "5%",
  color: colorText,
});
export const containerText = (colorText) => ({
  fontSize: {
    xs: "14px",
    sm: "16px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  }, // Tamaño de fuente responsivo para texto
  marginTop: "1vh",
  marginRight: "4vw",
  textAling: "justify",
  marginBottom: "1vh",
  color: colorText,
});

export const containerMobile = (color) => ({
  backgroundColor: color,
  boxShadow: "0 13px 19px rgba(0, 0, 0, 0.07)",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  paddingLeft: {
    xs: "2vh",
    sm: "3vh",
    md: "4vh",
    lg: "4vh",
    xl: "5vh",
  },
  paddingTop: "3vh",
  paddingBottom: "3vh",
  borderRadius: 2,
  width: {
    xs: "100%",
    sm: "90%",
    md: "100%",
  },
  height: {
    xs: "220px",
    sm: "200px",
    md: "280px",
    lg: "300px",
    xl: "300px",
  },
});
