// Layout.js
import React from "react";
import * as styles from "../styles/Layout";
import { Box } from "@mui/material";

const Layout = ({ children }) => {
  return <Box sx={styles.container}>{children}</Box>;
};

export default Layout;
