import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import * as styles from "../../styles/MoreFinancial.js";
import PricesChart from "../chart/PricesChart.js";
import * as button from "../../styles/button";
import CustomBox from "./CustomBox.js";
import ChartContainer from "./CharContainer.js";
import BoxCard from "./BoxCard.js";
import * as functions from "../../functions/calculoRatios.js";

const MoreFinancial = ({ image, stock, setFront }) => {
  console.log(stock)
  const containerRef = useRef(null);
  const variacion = functions.calculoVariacion(stock);

  const disablePageScroll = () => {
    document.body.style.overflow = "hidden";
  };

  // Función para restaurar el scroll de la página
  const enablePageScroll = () => {
    document.body.style.overflow = "auto";
  };

  // Se deshabilita el scroll al montar el componente
  useEffect(() => {
    disablePageScroll();
    return () => {
      // Se restaura el scroll al desmontar el componente
      enablePageScroll();
    };
  }, []);

  const scrollToComponent = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      }); // Center the component in the viewport
    }
  };

  useEffect(() => {
    scrollToComponent();
  }, []);

  const data = [
    {
      label: "Riesgo",
      value: "",
      tag: {
        text: stock.riesgo,
        colorChoice:
          stock.riesgo === "Bajo"
            ? "green"
            : stock.riesgo === "Medio"
            ? "orange"
            : "red",
      },
    },
    {
      label: "Deuda",
      value: "",
      tag: {
        text: stock.deuda,
        colorChoice:
          stock.deuda === "Baja"
            ? "green"
            : stock.deuda === "Media"
            ? "orange"
            : "red",
      },
    },
    {
      label: "Crecimiento",
      value: "",
      tag: {
        text: stock.crecimiento,
        colorChoice:
          stock.crecimiento === "Alto"
            ? "green"
            : stock.crecimiento === "Medio"
            ? "orange"
            : "red",
      },
    },
    {
      label: "Margenes",
      value: "",
      tag: {
        text: stock.margenes,
        colorChoice:
          stock.margenes === "Altos"
            ? "green"
            : stock.margenes === "Medios"
            ? "orange"
            : "red",
      },
    },
  ];

  const dataMobile2 = data.filter((item) =>
    ["Riesgo", "Deuda", "Crecimiento", "Margenes"].includes(item.label)
  );

  return (
    <Box ref={containerRef} sx={styles.containerStyle}>
      {/* MediaCard, Precio, Variacion, Volver */}
      <Box>
        <Box sx={styles.boxCardPrecio}>
          <Box sx={styles.boxCardMedia3}>
            <img src={image} alt={stock.name} style={{ height: "80%" }} />
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "contents" },
            }}
          >
            <Typography sx={styles.priceText}>
              {" "}
              Precio:{`$${stock.actual_price}`}
            </Typography>
            <Typography sx={styles.variacionText}>
              Variacion:{" "}
              <span style={{ color: variacion < 0 ? "red" : "#1a990f" }}>
                {variacion.toFixed(2)}%
              </span>
            </Typography>
          </Box>
          <Button
            sx={{
              ...button.styleButton,
              margin: "20px",
              width: "fit-content",
              height: "1em",
              padding: { xs: 2, md: 2.5 },
              boxShadow: 1,
            }}
            onClick={() => {
              setFront(true);
            }}
          >
            <Typography
              sx={{ ...button.styleButtonText, textTransform: "uppercase" }}
            >
              Volver
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <Box sx={styles.box2Financial}>
          <Box sx={styles.containerPriceChart}>
            <PricesChart stock={stock} />
          </Box>
          <Box sx={styles.containerAnalisis}>
            <Box
              sx={{
                marginTop: { xs: "0px", md: "3vh" },
                marginBottom: { xs: "1vh", md: "1vh" },
              }}
            >
              <>
                <CustomBox
                  data={dataMobile2}
                  sx={{ display: { xs: "flex", sm: "none" } }}
                />
                <CustomBox
                  data={data}
                  sx={{ display: { xs: "none", sm: "flex" } }}
                />
              </>
            </Box>

            <Box sx={styles.boxRatios}>
              <BoxCard text={"PER"} value={functions.calculoPer(stock)} tooltip={"Precio / Ganancias"} />
              <BoxCard
                text={"EV/EBITDA"}
                value={functions.calculoEvEbitda(stock)}
                tooltip={"(Capitalizacion + Deuda Neta) / EBITDA"}
              />
              <BoxCard
                text={"EV/FCF"}
                value={`${functions.calculoEvFcf(stock)}`}
                tooltip={"(Capitalizacion + Deuda Neta) / FCF"}
              />
              <BoxCard
                text={"P/Sales"}
                value={`${functions.calculoPSales(stock)}`}
                tooltip={"Precio / Ventas"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.containerGraficos,
              display: { xs: "flex", lg: "none" },
            }}
          >
            <ChartContainer stock={stock}></ChartContainer>
          </Box>
        </Box>
        {/* Grafico y Ratios */}
        <Box sx={styles.box3Financial}>
          <Box sx={styles.containerDescripcion}>
            <Typography sx={styles.descriptionText}>
              {stock.description}
            </Typography>
          </Box>
          {/* Grafico */}
          <Box
            sx={{
              ...styles.containerGraficos,
              display: { xs: "none", lg: "flex" },
            }}
          >
            <ChartContainer stock={stock}></ChartContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MoreFinancial;
