export const dialogContainerStyle = {
  marginLeft: "20px",
  marginRight: "20px",
  maxWidth: "320px",
  minHeight: { xs: "250px", md: "260px" },
  marginBottom: "50px",
  border: 1,
  padding: 3,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  justifyContent: "space-between",
};
export const dialogStyle = {
  textAlign: "center",
  fontWeight: "bold",
};
export const boxDialogStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
};
export const dialogTittleStyle = {
  fontSize: { xs: "2.8vh", md: "2.4vh" },
  fontWeight: "bold",
  textAlign: "center",
  color: "#2a4886",
};
export const textCardStyle = {
  fontSize: { xs: "2vh", md: "1.8vh" },
  marginBottom: "18px",
  paddingLeft: "15px",
  paddingRight: "15px",
  marginTop: "10px",
  textAlign: "center",
};
export const buttonStyle = {
  backgroundColor: "#2a4886",
  borderRadius: "12px",
};
export const buttonTextStyle = {
  fontSize: "12px",
  fontWeight: "bold",
  padding: "4px",
};
export const queSonText =
  "Los ratios financieros son indicadores utilizados para evaluar el desempeño financiero. Estas métricas se calculan a partir de los estados financieros y proporcionan información crucial para que los inversores tomen decisiones informadas. Permiten analizar diversos aspectos de una empresa, como su rentabilidad, solidez financiera, eficiencia operativa y valoración.";
export const paraQueSirvenText =
  "Permiten evaluar el rendimiento de una empresa (rentabilidad sobre activos, sobre patrimonio neto, etc.), medir la solidez financiera (Deuda/EBITDA, activos corrientes / pasivos corrientes, etc.), evaluar la eficiencia operativa (margen bruto, margen operativo, etc.), identificar el valor (PER, P/FCF, EV/FCF, etc.) y tomar decisiones compararando diferentes empresas.";
export const importanciaText =
  "Deben considerarse en conjunto y no debe ser el unico instrumento utilizado para tomar decisiones de inversión, ya que otros factores, como la estrategia de la empresa, la gestión y el entorno económico, también son relevantes. Permiten evaluar y comparar el rendimiento de las empresas y tomar decisiones fundamentadas en cuanto a la inversión en acciones.";
export const backgroundStyle = {
  backgroundColor: "rgba(25, 57, 139, 0.15)",
};
export const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
export const boxStyle = {
  width: { xs: "90%", md: "70%" },
  backgroundColor: "white",
  border: 1,
  borderColor: "gray",
  borderRadius: "20px",
  padding: { xs: "0px", md: "25px" },
  marginTop: "30px",
  minHeight: { xs: "450px", md: "0px" },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
export const boxTextStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export const tittleText = {
  fontWeight: "bold",
  marginBottom: "12px",
  paddingTop: "25px",
  color: "#2a4886",
  textAlign: "center",
  fontSize: { xs: "3vh", md: "2.8vh" },
};
export const textStyle = {
  marginLeft: { md: "80px" },
  marginRight: { md: "45px" },
  fontSize: { xs: "2.4vh", md: "2.1vh" },
  paddingLeft: { xs: "20px", md: "0px" },
  paddingRight: { xs: "20px", md: "0px" },
  textAlign: "center",
};
export const boxCircle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "25px",
  marginBottom: { xs: "20px", md: "0px" },
};
export const ratiosTittle = {
  marginTop: "45px",
  marginBottom: "30px",
  fontWeight: "bold",
  fontSize: "30px",
  color: "#FFFF",
  textAlign: "center",
};
export const ratiosBox = {
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  alingContent: "center",
};
