import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../2.png";
import { useMediaQuery } from '@mui/material';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Cambia el valor según tus necesidades

  return (
    <Container component="footer" maxWidth={false} style={{ backgroundColor: "#2a4887", padding: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Link href="/" style={{ textDecoration: 'none' }}><img src={Logo} alt="Logo" style={{ maxHeight: "180px", maxWidth: "180px", marginRight: "40px" }} /></Link>
      <Typography variant="body2" color="white" sx={{ fontSize: "18px", marginRight: isMobile ? "0" : "40px", display: isMobile ? 'none' : 'block' }}>
        © {new Date().getFullYear()} InverSimple - {isMobile ? null : 'Todos los derechos reservados'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <Link href="/contacto" style={{ fontSize: "18px", color: 'white', textDecoration: 'none' }}>
          Contacto
        </Link>
      </Typography>
    </Container>
  );
};

export default Footer;