import React, { useEffect, useState, useContext } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { PreciosContext } from "../hooks/PricesContext";
import * as functions from "../functions/calculoCartera";
import * as styles from "../styles/MiPorfolio";
import TablePorfolio from "../components/portfolio/TablePortfolio";
import TablePorfolioMobile from "../components/portfolio/TablePortfolioMobile";
import Form from "../components/portfolio/Form";
import CardPortfolio from "../components/portfolio/Card";
import TextFieldCodigo from "../components/portfolio/TextField";
import DialogCartera from "../components/portfolio/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const MiPortfolio = () => {
  const { stocks, dolarCCL } = useContext(PreciosContext);
  const [acciones, setAcciones] = useState([]);
  const [formData, setFormData] = useState({
    ticker: "",
    cantidad: "",
  });
  const [chartData, setChartData] = useState([]);
  const [codigoPortfolio, setCodigoPortfolio] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isMobile2, setIsMobile2] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (acciones) {
      actualizarChartData(acciones);
    }
  }, [acciones]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280);
      setIsMobile2(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowDialog(true);
  }, []);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const actualizarChartData = (data) => {
    const newData = data.map((accion) => ({
      name: accion.ticker,
      value: functions.valorAccion(
        accion.ticker,
        accion.cantidad,
        stocks,
        dolarCCL
      ),
    }));
    setChartData(newData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "cantidad" && parseInt(value) > 10000000) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validar el ticker: solo entre 1 y 5 letras
    if (formData.ticker.length >= 1 && formData.ticker.length <= 5) {
      // Verificar si ya existe una acción con el mismo ticker
      const existingIndex = acciones.findIndex(
        (acc) => acc.ticker === formData.ticker
      );
      if (existingIndex !== -1) {
        // Si ya existe, sumar la cantidad a la acción existente
        const nuevasAcciones = [...acciones];
        nuevasAcciones[existingIndex].cantidad += parseInt(
          formData.cantidad,
          10
        ); // Convertir a número
        setAcciones(nuevasAcciones);
      } else {
        // Si no existe, agregar la acción al estado de acciones
        setAcciones((prevAcciones) => {
          const nuevasAcciones = [
            ...prevAcciones,
            {
              ...formData,
              cantidad: parseInt(formData.cantidad, 10),
            },
          ];

          actualizarChartData(nuevasAcciones); // Llamar a actualizarChartData con las nuevas acciones
          return nuevasAcciones;
        }); // Convertir a número
      }
      // Limpiar el formulario
      setFormData({ ticker: "", cantidad: "" });
    }
  };

  const handleEliminarAccion = (ticker) => {
    const nuevasAcciones = acciones.filter(
      (accion) => accion.ticker !== ticker
    );
    setAcciones(nuevasAcciones);
  };

  const handleChangeCodigoPortfolio = (event) => {
    if (!event) {
      console.error("No hay codigo");
      return;
    }
    setCodigoPortfolio(event.target.value);
  };

  const handleSubmitCodigoPortfolio = () => {
    const nuevasAcciones = functions.generarPortfolio({ codigoPortfolio }); // Llama a generarPortfolio con el código ingresado
    setAcciones(nuevasAcciones); // Actualiza el estado de acciones con las acciones generadas
    setCodigoPortfolio(""); // Limpia el input después de procesar el código
    handleCloseDialog();
  };

  return (
    <Box sx={styles.containerStyle}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1,
        }}
      >
        <Form
          formData={formData}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          opciones={stocks}
          isMobile={isMobile}
        ></Form>
        {!isMobile2 ? (
          <TablePorfolio
            styles={styles}
            acciones={acciones}
            functions={functions}
            stocks={stocks}
            dolarCCL={dolarCCL}
            handleEliminarAccion={handleEliminarAccion}
          />
        ) : (
          <TablePorfolioMobile
            styles={styles}
            acciones={acciones}
            functions={functions}
            stocks={stocks}
            dolarCCL={dolarCCL}
            handleEliminarAccion={handleEliminarAccion}
          />
        )}
        {isMobile && (
          <Box sx={{ marginTop: "20px" }}>
            <CardPortfolio
              acciones={acciones}
              stocks={stocks}
              chart={chartData}
              dolarCCL={dolarCCL}
              ChartHeight={200}
            />
          </Box>
        )}
        <Box sx={styles.containerDialog}>
          <DialogCartera />
        </Box>
      </Box>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            flex: 0.2,
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TextFieldCodigo
              codigoPortfolio={codigoPortfolio}
              handleChangeCodigoPortfolio={handleChangeCodigoPortfolio}
              handleSubmitCodigoPortfolio={handleSubmitCodigoPortfolio}
            />
            <CardPortfolio
              acciones={acciones}
              stocks={stocks}
              chart={chartData}
              dolarCCL={dolarCCL}
              ChartHeight={350}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <Dialog open={showDialog} onClose={handleCloseDialog}>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={styles.Dialog}>
              <TextFieldCodigo
                codigoPortfolio={codigoPortfolio}
                handleChangeCodigoPortfolio={handleChangeCodigoPortfolio}
                handleSubmitCodigoPortfolio={handleSubmitCodigoPortfolio}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default MiPortfolio;
