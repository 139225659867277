export const conservador = {
  type: "Conservador",
  order: 1,
  heading: "Cartera Conservadora",
  panels: [
    {
      id: "conservador1",
      content:
        "Una cartera de acciones conservadora se caracteriza por su enfoque en la seguridad y la estabilidad de la inversión. En este tipo de cartera, la mayoría de las inversiones se destinan a acciones de empresas bien establecidas y de bajo riesgo, así como a bonos y activos más seguros. El objetivo principal es preservar el capital y generar un flujo de ingresos estable a lo largo del tiempo. Esta cartera suele ser adecuada para inversores que buscan un enfoque de inversión a largo plazo y desean evitar una exposición significativa a la volatilidad del mercado.",
    },
    {
      id: "conservador2",
      content:
        "Incluye acciones de sectores estables como retail, con Coca Cola, Pepsi, Walmart y McDonald's. Empresas farmacéuticas y de cuidado personal como Johnson & Johnson y Procter & Gamble también se destacan por su resistencia en recesiones.",
    },
    {
      id: "conservador3",
      content:
        "Con menor peso, JPMorgan brinda exposición al crecimiento mundial, Visa destaca en pagos sin riesgo de impagos, y AT&T ofrece dividendo atractivo y baja volatilidad. Las tecnológicas Apple y Microsoft, junto con el holding Berkshire Hathaway de Warren Buffet, completan la cartera.",
    },
  ],
};

export const moderado = {
  type: "Moderado",
  order: 2,
  heading: "Cartera Moderada",
  panels: [
    {
      id: "moderado1",
      content:
        "Una cartera de acciones moderada equilibra el riesgo y la recompensa. En esta cartera, se invierte en una combinación de acciones de empresas establecidas y bonos de calidad. El objetivo es lograr un crecimiento del capital a través de las inversiones en acciones, al tiempo que se mantiene cierto nivel de estabilidad y generación de ingresos mediante los bonos. Esta cartera es adecuada para inversores que desean un equilibrio entre el crecimiento a largo plazo y la protección contra la volatilidad del mercado.",
    },
    {
      id: "moderado2",
      content:
        "Con menor ponderación tenemos a HP Inc y 3M, empresas industriales con marcas y productos respetables y difíciles de reemplazar. Encontramos a Visa, principal gestora de pagos del mundo y Johnson & Johnson con productos de higiene lideres a nivel mundial.",
    },
    {
      id: "moderado3",
      content:
        "Tenemos a Disney, que forma parte de todas nuestras infancias, generando un activo intangible incalculable y AT&T, empresa de telecomunicaciones que aporta estabilidad a la cartera. Nos encontramos a Paypal, una de las Fintech más confiables del mundo y terminamos con Pfizer, empresa de farmacéuticos que luego de ganar mucho dinero durante el covid está pasando por un momento financiero complicado.",
    },
  ],
};

export const arriesgado = {
  type: "Arriesgado",
  order: 3,
  heading: "Cartera Arriesgada",
  panels: [
    {
      id: "arriesgado1",
      content:
        "Una cartera de arriesgada se centra en la búsqueda de altos retornos a través de inversiones en acciones más volátiles y mayor riesgo. Podemos encontrar acciones de empresas emergentes, startups y sectores más volátiles del mercado. El objetivo es maximizar el potencial de crecimiento, pero a costa de una mayor exposición al riesgo. Esta cartera es adecuada para inversores que buscan un alto rendimiento y un horizonte de inversión a largo plazo para poder tolerar la volatilidad y las fluctuaciones del mercado.",
    },
    {
      id: "arriesgado2",
      content:
        "Destaca una mayor ponderación en el sector tecnológico, con Alphabet y Amazon como principales posiciones. Ambas empresas, creciendo muy rápido y abarcando cada vez más mercado. Alibaba y Paypal, aunque atraviesan caídas en bolsa, están muy sanas financieramente. 3M y Disney que están pasando por momentos financieros complicados, pero confiamos en que ambas van a recuperarse y dar una muy buena rentabilidad.",
    },
    {
      id: "arriesgado3",
      content:
        "HP Inc, representa una apuesta segura en la fabricación de computadoras. Además, tenemos cuatro posiciones pequeñas en empresas con valoraciones actuales menos atractivas, pero con un potencial significativo a futuro. Tesla, líder en autos eléctricos, Meta (Facebook, Instagram, Whatsapp), AMD (fabricante de componentes electrónicos) y MercadoLibre, se destacan por su prometedor potencial.",
    },
  ],
};
