export const styleContainer = {
  height: "100%",
  minHeight: "90vh",
  marginTop: "0px",
  paddingBottom: "30px",
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingInline: "4rem",
};

export const styleContainerTable = {
  backgroundColor: "rgba(0,0,0,0)",
  "&::-webkit-scrollbar": {
    width: "12px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
};
