const colorMap = {
  green: {
    backgroundColor: "#e5f4ea",
    text: "#549a6b",
  },
  red: {
    backgroundColor: "#fce8e6",
    text: "#a50e13",
  },
  default: {
    backgroundColor: "#F1F1F1",
    text: "#000",
  },
};

export const boxCardPrecio = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  height: { xs: "9vh", md: "12vh" },
  paddingInline: "10px",
};

export const containerPriceChart = {
  backgroundColor: "white",
  height: { xs: "42vh", md: "40vh" },
  marginBottom: "3vh",
  marginLeft: { xs: "2vh", lg: "6vh" },
  marginRight: { xs: "2vh", lg: "6vh" },
  borderRadius: "2vh",
};

export const containerAnalisis = {
  display: "flex",
  flexDirection: "column",
  height: { xs: "34vh", md: "36vh" },
  backgroundColor: "white",
  marginLeft: { xs: "2vh", lg: "6vh" },
  marginRight: { xs: "2vh", lg: "6vh" },
  borderRadius: "2vh",
  marginBottom: "2vh",
  justifyContent: "center",
  gap: 1,
};

export const containerDescripcion = {
  display: { xs: "none", lg: "flex" },
  alignItems: "center",
  backgroundColor: "white",
  height: "25vh",
  marginBottom: "3vh",
  marginRight: "6vh",
  borderRadius: "2vh",
};

export const containerGraficos = {
  backgroundColor: "white",
  height: { xs: "44vh", lg: "51vh" },
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginRight: { xs: "2vh", lg: "6vh" },
  marginLeft: { xs: "2vh", lg: "0vh" },
  borderRadius: "2vh",
  marginBottom: "2vh",
};

export const containerStyle = {
  overflow: { xs: "scroll", lg: "hidden" },
  width: "100%",
  height: "94vh",
  boxShadow: 6,
  borderRadius: 5,
  marginLeft: { xs: "20px", md: "65px" },
  marginRight: { xs: "20px", md: "65px" },
  marginTop: "40px",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  display: "flex",
  flexDirection: "column",
};

export const box2Financial = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "100%", lg: "60%" },
};

export const box3Financial = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "none", lg: "45%", xl: "50%" },
};

export const financialInfo2 = {
  marginTop: { xs: "3vh", md: "1.4vh" },
  marginBottom: "3.4vh",
  fontSize: { xs: "1.9vh", md: "2.4vh" },
  textAlign: "center",
  fontWeight: "bold",
};
export const volverButton = {
  padding: 1,
  height: { xs: "30px", md: "40px" },
  color: "#2a4886",
  width: "9%",
  marginLeft: "10px",
  marginRight: "10px",
};
export const volverText = {
  fontSize: { xs: "14px", md: "16px" },
  fontWeight: "bold",
  color: "white",
};
export const priceText = {
  padding: 1,
  fontSize: { xs: "14px", sm: "16px", md: "3vh" },
  fontWeight: "bold",
  width: { xs: "35%", md: "20%" },
  textAlign: "center",
  display: "flex",
};
export const variacionText = {
  padding: 1,
  fontSize: { xs: "14px", sm: "16px", md: "3vh" },
  width: { xs: "40%", md: "20%" },
  fontWeight: { xs: "bold" },
  textAlign: "center",
  display: "flex",
  alignItems: "center",
};

export const separadorStyle3 = {
  marginRight: "70px",
  marginLeft: "70px",
  color: "lightgray",
  border: 1,
};

export const boxCardMedia = {
  height: 80,
  width: 280,
  padding: 1,
  marginRight: "40px",
  marginLeft: "40px",
  display: { xs: "none", md: "block" },
};

export const boxCardMedia3 = {
  height: "90%",
  paddingTop: 1,
  marginRight: "20px",
};
export const descriptionText = {
  color: "black",
  fontSize: { xs: "1.94vh", md: "16px", lg: "18px" },
  marginRight: { xs: "30px", md: "40px" },
  marginLeft: { xs: "30px", md: "40px" },
  textAlign: "justify",
};

export const ratiosText = {
  marginTop: "2vh",
  fontSize: { xs: "2.2vh", md: "14px", lg: "2.8vh" },
  fontWeight: "bold",
  marginBottom: { xs: "0px", md: "1vh", xl: "1vh" },
  color: "#2a4886",
  textAlign: "center",
};
export const box2Ratios = { display: "flex", flexDirection: "row" };
export const priceText2 = {
  padding: 1,
  fontSize: "2.8vh",
  fontWeight: "bold",
  width: "60%",
  textAlign: "center",
};
export const variacionText2 = {
  padding: 1,
  fontSize: "2.8vh",
  width: "40%",
  textAlign: "center",
};
export const boxMobileStyle = {
  display: { xs: "flex", md: "none" },
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "0px",
};
export const boxCardMedia2 = {
  height: "10vh",
  width: "30vh",
  padding: 1,
  mr: "2vh",
};
export const volverButton2 = {
  padding: 1,
  height: "5.4vh",
  color: "#2a4886",
  borderRadius: 4,
  mr: "2vh",
};

export const boxAnalisis = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const priceStyle = {
  margin: "0.5vh",
  fontSize: { xs: "12px", md: "18px", lg: "18px" },
  textAlign: "center",
  marginLeft: { xs: "3vw", sm: "0vw", md: "0vw" },
  marginRight: { xs: "3vw", sm: "0vw" },
};

export const containerDatos = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-evenly",
  alignContent: "center",
  width: "100%",
  height: { xs: "30%", md: "50%" },
  marginBottom: { xs: 0, sm: 1, md: "none" },
};

export const boxPriceFullScreen = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
};

export const boxRatios = {
  flex: "0 0 auto", // Ancho responsivo
  display: "grid",
  flexDirection: "row",
  paddingInline: {
    xs: "20px",
    sm: "30px",
    md: "30px",
    lg: "30px",
    xl: "30px",
  },
  paddingBlock: "3vh",
  borderRadius: 3,
  gap: {
    xs: 2,
    sm: 4,
  },
  gridTemplateColumns: "repeat(4, 1fr)",
};

export const styleBoxContainer = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  flex: 0.6,
  width: "100%",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
};
export const styleBoxText = {
  fontSize: { xs: "11px", md: "14px", lg: "16px", xl: "14px" },
  textTransform: "uppercase",
};
export const styleBoxValue = {
  fontSize: { xs: "13px", sm: "14px", md: "16px", lg: "18px", xl: "18px" },
  textTransform: "uppercase",
};

export const styleBox = (color) => ({
  width: { xs: "100%", md: "70%" }, // Ancho responsivo
  height: "fit-content",
  backgroundColor: colorMap[color]
    ? colorMap[color].backgroundColor
    : colorMap.default.backgroundColor,
  display: "flex",
  flexDirection: "column",
  paddingBlock: "2vh",
  borderRadius: 4,
  color: colorMap[color] ? colorMap[color].text : colorMap.default.text,
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
});
