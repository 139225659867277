import React from "react";
import { Box, Typography } from "@mui/material";
import * as title from "../../constants/titles_home.js";
import * as styles from "../../styles/Home.js";
import imageSVG from "../../utils/images/image_objetive.svg";
import Item from "../Item.js";

const Objetivo = () => {
  return (
    <Box sx={styles.styleContenedorTextcard}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={styles.styleTextcardTitle}>
          ¿Por qué Inversimple?
        </Typography>
        <Typography sx={styles.styleTextcardText}>
          {title.porqueInversimple}
        </Typography>
      </Box>
      <Box sx={styles.styleContenedorItemImage}>
        <Box sx={styles.styleBoxImage}>
          <img
            style={{ width: "100%" }}
            src={imageSVG}
            alt="Descripción de la imagen"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "column" },
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {title.porqueInversimpleItems.map((data, index) => (
            <Item
              key={index}
              title={data.title}
              text={data.text}
              icon={data.image}
              colorBox={"#2A4887"}
              colorText={"#000"}
            ></Item>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Objetivo;
