import { FormControl, TextField, Typography } from "@mui/material";
import * as styles from "../styles/TextFieldNum";

export default function TextFieldNum({
  formData,
  handleChange,
  errors,
  ...textFieldProps
}) {
  return (
    <FormControl fullWidth error={!!errors.cantidad} sx={{ minHeight: "64px" }}>
      <TextField
        {...textFieldProps}
        id="cantidad"
        type="number"
        value={formData.cantidad}
        onChange={(e) => handleChange(e)}
        error={!!errors.cantidad}
        sx={{
          ...styles.TextFieldStyle,
          "& input": {
            marginTop: "-8px",
          },
        }}
        inputProps={{ min: 1 }}
        onKeyDown={(e) => {
          if (e.key === "-" || e.key === "e") {
            e.preventDefault();
          }
        }}
        InputLabelProps={{
          sx: { marginTop: "-7px" },
        }}
      />
      {errors.cantidad && (
        <Typography variant="caption" color="error" ml={1}>
          {errors.cantidad}
        </Typography>
      )}
    </FormControl>
  );
}
