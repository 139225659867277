import { Box, Typography } from "@mui/material";
import * as styles from "../styles/MiPorfolio";

const BoxCard = ({ text, value, color, sx }) => {
  return (
    <Box sx={styles.styleBoxContainer}>
      <Typography sx={styles.styleBoxText}>{text}</Typography>
      <Box sx={styles.styleBox(color)}>
        <Typography
          sx={{
            ...styles.styleBoxValue,
            fontWeight: "bold",
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        ></Typography>
      </Box>
    </Box>
  );
};
export default BoxCard;
