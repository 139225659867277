import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as styles from "../../styles/Form";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as button from "../../styles/button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import TextFieldNum from "../TextFieldNum";

const Form = ({ formData, handleSubmit, handleChange, opciones, isMobile }) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.ticker) {
      newErrors.ticker = "Seleccione un ticker";
    }
    if (!formData.cantidad || formData.cantidad < 1) {
      isMobile
        ? (newErrors.cantidad = "Ingrese valor")
        : (newErrors.cantidad = "Ingrese una cantidad");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return (
    <Box>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          if (validateForm()) {
            handleSubmit(e);
          }
        }}
      >
        <Box sx={styles.containerBox}>
          <Box sx={styles.containerSelect}>
            <FormControl
              fullWidth
              error={!!errors.ticker}
              sx={{ minHeight: "64px" }}
            >
              <InputLabel
                id="ticker-label"
                sx={{
                  overflow: "visible",
                  lineHeight: 0.5,
                  "&:focus": {
                    color: "#2A4887",
                  },
                }}
              >
                Cedear
              </InputLabel>
              <Select
                labelId="ticker-label"
                id="ticker"
                name="ticker"
                value={formData.ticker}
                input={<OutlinedInput label="Ticker" />}
                onChange={handleChange}
                sx={styles.selectStyle}
              >
                {opciones.map((opcion, index) => (
                  <MenuItem
                    key={index}
                    value={opcion.ticker}
                  >{`${opcion.ticker} - ${opcion.name}`}</MenuItem>
                ))}
              </Select>
              {errors.ticker && (
                <Typography variant="caption" color="error" ml={1}>
                  {errors.ticker}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box sx={styles.containerTextField}>
            <TextFieldNum
              formData={formData}
              handleChange={handleChange}
              errors={errors}
              label="Cantidad"
              name="cantidad"
            />
          </Box>
          <Box sx={styles.containerButton}>
            {isMobile ? (
              <IconButton type="submit">
                <AddIcon sx={{ color: "#2A4887" }} />
              </IconButton>
            ) : (
              <Button type="submit" sx={button.styleButton}>
                <Typography sx={button.styleButtonText}>Agregar</Typography>
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
