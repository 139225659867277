import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const DonutChart2 = ({ Chartdata, height }) => {
  let data = Chartdata;

  const COLORS = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF66CC",
    "#66FF99",
    "#FF9900",
    "#FF5733",
    "#22FFA7",
    "#FFCC00",
    "#8542FF",
  ];

  return (
    <ResponsiveContainer width={"100%"} height={height}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={"40%"}
          outerRadius={"70%"}
          fill="#8884d8"
          label={({ name, value, percent }) =>
            `${name} (${(percent * 100).toFixed(0)}%)`
          }
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart2;
