import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../../styles/MiPorfolio.js";
import EmptyChart from "../../utils/images/pie-chart.svg";
import * as functions from "../../functions/calculoCartera.js";
import DonutChart from "../chart/PortfolioChart.js";

import BoxCard from "../BoxCard.js";

const Card = ({ acciones, stocks, chart, dolarCCL, ChartHeight }) => {
  const valueUSD = functions
    .calcularValorTotal("USD", acciones, stocks, dolarCCL)
    .toFixed(0);
  const valueARS = functions
    .calcularValorTotal("ARS", acciones, stocks, dolarCCL)
    .toFixed(0);

  const PER = functions
    .calculoPerCartera(acciones, stocks, dolarCCL)
    .toFixed(2);
  const EV = functions
    .calculoEVtoFCFCartera(acciones, stocks, dolarCCL)
    .toFixed(2);
  const FCF = (
    100 / functions.calculoEVtoFCFCartera(acciones, stocks, dolarCCL)
  ).toFixed(2);
  const variacionPortfolio = functions.calcularVariacionPortfolio(
    acciones,
    stocks,
    dolarCCL
  );

  const variacionUSD = functions.calcularVariacionPortfolioUSD(
    variacionPortfolio,
    valueUSD
  );

  const formatValue = (value) => {
    // Verifica si el valor es NaN, 0 o Infinity
    if (isNaN(value) || parseFloat(value) === 0 || !isFinite(value)) {
      return "---";
    } else
      return parseFloat(value).toLocaleString("es-ES", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        minWidth: "28vw",
      }}
    >
      <Box sx={styles.styleCardContainer}>
        <BoxCard
          text={"Valor Portfolio"}
          value={`USD &nbsp $ ${formatValue(
            valueUSD
          )} <br /> <br /> ARS &nbsp $ ${formatValue(valueARS)}`}
        />
        <BoxCard
          text={"Variación Cartera"}
          value={` ${
            variacionPortfolio > 0 ? "🠝" : variacionPortfolio < 0 ? "🠟" : ""
          } ${formatValue(variacionPortfolio)} %  <br /> <br /> ${
            variacionPortfolio > 0 ? "🠝" : variacionPortfolio < 0 ? "🠟" : ""
          }  USD ${formatValue(variacionUSD)} `}
          color={
            variacionPortfolio > 0
              ? "green"
              : variacionPortfolio < 0
              ? "red"
              : ""
          }
        />
      </Box>
      <Box sx={styles.styleCardChart}>
        {chart.length === 0 ? (
          <Box sx={styles.styleBoxChart}>
            <img
              src={EmptyChart}
              alt={"emptyChart"}
              style={{ height: "25vh" }}
            />
            <Typography sx={{ textAlign: "center" }}>
              Aún no se han agregado CEDEARS
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DonutChart Chartdata={chart} height={ChartHeight} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...styles.styleCardContainer,
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      >
        <BoxCard text={"PER Ponderado"} value={formatValue(PER)} />
        <BoxCard text={"EV/FCF Ponderado"} value={formatValue(EV)} />
        <BoxCard text={"FCF Yield"} value={`${formatValue(FCF)} %`} />
      </Box>
    </Box>
  );
};

export default Card;
