import React, { useState, useEffect } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useMediaQuery, useTheme } from "@mui/material";

const DonutChart = ({ profile, height }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Función para actualizar las dimensiones de la ventana
  const updateWindowDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Escucha el cambio en el tamaño de la pantalla
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  let innerRadius, outerRadius;
  if (windowDimensions.width < 1600) {
    innerRadius = isMobile ? 65 : 100;
    outerRadius = isMobile ? 95 : 150;
  } else {
    innerRadius = isMobile ? 80 : 70;
    outerRadius = isMobile ? 110 : 110;
  }

  let data;
  if (profile === "Conservador") {
    data = [
      { name: "JP Morgan", value: 12 },
      { name: "Mc Donalds", value: 10 },
      { name: "Walmart", value: 10 },
      { name: "Visa", value: 10 },
      { name: "P&G", value: 8 },
      { name: "AT&T", value: 8 },
      { name: "J&J", value: 8 },
      { name: "Berkshire", value: 8 },
      { name: "Coca Cola", value: 7 },
      { name: "Pepsico", value: 7 },
      { name: "Apple", value: 6 },
      { name: "Microsoft", value: 6 },
    ];
  } else if (profile === "Moderado") {
    data = [
      { name: "Google", value: 10 },
      { name: "Bank of America", value: 10 },
      { name: "Pepsico", value: 10 },
      { name: "Amazon", value: 8 },
      { name: "HP Inc", value: 8 },
      { name: "Visa", value: 8 },
      { name: "J&J", value: 8 },
      { name: "3M", value: 8 },
      { name: "Disney", value: 8 },
      { name: "AT&T", value: 8 },
      { name: "Paypal", value: 7 },
      { name: "Pfizer", value: 7 },
    ];
  } else if (profile === "Arriesgado") {
    data = [
      { name: "Google", value: 16 },
      { name: "Bank of America", value: 10 },
      { name: "3M", value: 10 },
      { name: "Alibaba", value: 10 },
      { name: "Paypal", value: 10 },
      { name: "Amazon", value: 8 },
      { name: "Disney", value: 8 },
      { name: "HP Inc", value: 8 },
      { name: "Tesla", value: 5 },
      { name: "Meta", value: 5 },
      { name: "AMD", value: 5 },
      { name: "Mercado Libre", value: 5 },
    ];
  } else {
    // Un valor por defecto o manejo de error si el perfil no coincide
    data = [];
  }

  const COLORS = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF66CC",
    "#66FF99",
    "#FF9900",
    "#FF5733",
    "#22FFA7",
    "#FFCC00",
    "#8542FF",
  ];

  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
          label={({ name, value, percent }) =>
            `${name} (${(percent * 100).toFixed(0)}%)`
          }
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
