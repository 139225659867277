import React from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import * as title from "../../constants/titles_home.js";
import * as styles from "../../styles/Home.js";
import imageCedear from "../../utils/images/CedearImage.png";
import arrow from "../../utils/images/fi_arrow-down-left.svg";
import Item from "../Item.js";

const Cedears = (props) => {
  const theme = useTheme();

  // Utilizar useMediaQuery para detectar el breakpoint actual
  const isXLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  // Determinar el tamaño de la imagen según el breakpoint actual
  let imagewidth;
  if (isXLarge) {
    imagewidth = "35vw";
  } else if (isLarge) {
    imagewidth = "35vw";
  } else if (isMedium) {
    imagewidth = "40vw";
  }
  return (
    <Box sx={styles.styleContenedorCedears}>
      <Box sx={{ flex: 0.5, marginTop: "" }}>
        <Box sx={styles.styleContenedorCedearsItem}>
          {title.cedearsItems.map((data, index) => (
            <Item
              key={index}
              title={data.title}
              text={data.text}
              icon={data.image}
              colorBox={"#FFFF"}
              colorText={"#FFFF"}
              colorIcon={"#2A4887"}
            ></Item>
          ))}
        </Box>
      </Box>
      <Box sx={{ flex: 0.5 }}>
        <Typography sx={styles.styleCedearsTitle}>CEDEARS</Typography>
        <Typography sx={styles.styleCedearsText}>{title.cedears}</Typography>
        <Box sx={styles.styleContenedorCedearsImage}>
          <img
            src={imageCedear}
            alt={"Cedear"}
            style={{ width: imagewidth, height: "auto" }}
          />

          <Button sx={styles.styleCedearsButton} onClick={props.handleClick}>
            <Typography sx={styles.styleCedearButtonText}>
              Conoce más
            </Typography>
            <Box sx={styles.styleCedearButtonIcon}>
              <img src={arrow} alt={title} />
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Cedears;
